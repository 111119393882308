.queue-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5; /* start with header partially visible */
    transition: opacity 0.3s; /* smooth transition effect */
    z-index: 10; /* ensure the header stays on top of other elements */
    flex-direction: column;
    z-index: 1000;
}

.queue-header.show {
    opacity: 1; /* fully visible */
}

.queue-header.hide {
    opacity: 0; /* completely hidden */
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Spacing between the buttons */
    transition: opacity 0.3s; /* smooth transition effect */
}

.button-container:hover {
    opacity: 1; /* fully visible on hover */
}

.queue-btn-back, .queue-btn-settings {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 10vw;
    max-width: 250px;
    background-color: #68f60c; /* green color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
}

.queue-btn-back:hover, .queue-btn-settings:hover {
    background-color: #45a049; /* a shade darker on hover */
}

.header-image {
    display: block;
    margin: 10px auto 0; /* 10px margin at the top to separate from buttons, auto centers the image horizontally */
    max-width: 30%; /* Ensures the image does not overflow the container */
    height: auto; /* Maintains the aspect ratio */
}

@media (max-width: 700px) {
    .header-image {
        max-width: 70%;
    }
}
