.edit-user-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.edit-user-container h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
}

.edit-user-container h3 {
    font-size: 1.3em;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #555;
}

.edit-user-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.edit-user-container li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.edit-user-container input[type="checkbox"] {
    margin-right: 10px;
}

.edit-user-container button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 10px;
    max-width: 200px;
}

.red-back {
    background-color: red !important;
}

.edit-user-container button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.edit-user-container .user-privileges {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.edit-user-container .user-privileges label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.edit-user-container .user-privileges input[type="checkbox"] {
    margin-right: 10px;
}