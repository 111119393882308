.scrolling-sync-settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.scrolling-sync-settings-modal {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.scrolling-sync-settings-title {
    color: #333;
    margin-bottom: 20px;
}

.scrolling-sync-settings-section {
    margin-bottom: 24px;
}

.scrolling-sync-settings-section h3 {
    margin-bottom: 12px;
    color: #333;
}

.scrolling-sync-settings-layout-buttons,
.scrolling-sync-settings-sync-buttons {
    display: flex;
    gap: 10px;
}

.scrolling-sync-settings-button {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.scrolling-sync-settings-button.active {
    background: #4CAF50;
    color: white;
    border-color: #4CAF50;
}

.scrolling-sync-settings-filter-controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.scrolling-sync-settings-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
}

.scrolling-sync-settings-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.scrolling-sync-settings-cancel,
.scrolling-sync-settings-save {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.scrolling-sync-settings-cancel {
    background: #f5f5f5;
    border: 1px solid #ddd;
}

.scrolling-sync-settings-save {
    background: #4CAF50;
    color: white;
    border: none;
} 