.fullscreen-slideshow img, .fullscreen-slideshow video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity ease-in-out;
    z-index: 0;  /* Default layer for all images and videos */
}

.fullscreen-slideshow img.active-slide, .fullscreen-slideshow video.active-slide {
    opacity: 1;
    z-index: 1;  /* Bring the active slide to the front */
}