/* SlideshowsPage.css */

.slideshows-page {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG4.png');
    background-size: 100vw;
    background-position: center top -60vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}


.header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.slideshows-list-container {
    padding: 5px;
    background-color: #68f60c;
    overflow-y: hidden;
    border-radius: 10px;
}

.slideshows-list {
    max-height: 80vh;
    overflow-y: auto;
    padding: 10px;
    background-color: black;
    border-radius: 10px;
    padding-bottom: 100px;
}

.slideshow-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    border-radius: 10px;
}

.slideshow-name {
    flex-grow: 1;
    text-align: center;
    justify-content: flex-end;
    font-size: 24px;
    color: white;
    font-weight: bold;
    display: flex;

}
.title-name-spacing {
    width: 80%;
    text-align: left;
    font-size: 24px;
    color: white;
    font-weight: bold;
}

.btn-play, .btn-edit, .btn-delete {
    margin-left: 5px;
    padding: 10px 20px;
    border: none;
    background-color: #68f60c;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}

.btn-play:hover {
    background-color: #68f60c;
}

.btn-edit {
    background-color: #7061AC;
}

.btn-edit:hover {
    background-color: #57487D;
}

.btn-delete {
    background-color: #D15091;
}

.btn-delete:hover {
    background-color: #B03C79;
}

.btn-back, .btn-add {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 180px;
    background-color: #68f60c;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}

.btn-back:hover, .btn-add:hover {
    background-color: #68f60c;
}


.slideshow-preview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* Add any padding or margin if required */
    width: 480px;
}



.slideshow-preview img {
    /* Adjust these values as per your requirements */
    width: 50px;  /* or whatever size you want */
    height: 50px;
    margin: 0 5px;  /* giving space between images */
    object-fit: cover; /* to ensure images cover the given width and height without distortion */
}

.btn-edit {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 110px;
    background-color: #7061AC; /* green color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
}

.btn-edit:hover {
    background-color: #68f60c; /* a shade darker on hover */
}

.btn-delete {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 110px;
    background-color: #D15091; /* red color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
}

.btn-delete:hover {
    background-color: #68f60c; /* a shade darker on hover */
}

.btn-play {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 70px;
    background-color: rgb(20,220,255); /* red color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    margin-right: 20px;
    border-radius: 5px;
}

.btn-play:hover {
    background-color: #68f60c; /* a shade darker on hover */
}

.video-preview {
    width: 50px;  /* or whatever size you prefer */
    height: 50px; /* or whatever size you prefer */
    background-color: black;
    border: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Adjust as necessary */
    color: white;
}

.center-header{
    justify-content: flex-end;
    display: flex;
    padding-bottom: 10px;

}


/* Media query for small screens */
@media screen and (max-width: 1000px) {
    .slideshows-list {
        max-height: 70vh;
        padding: 10px;
        background-color: black;
    }
    .slideshow-preview {
        display: none;
    }
    .title-name-spacing {
        width: auto;
        font-size: 14px;
        color: white;
        font-weight: normal;
    }
    .slideshow-name{
        justify-content: left;
    }
}

@media screen and (max-width: 560px) {
    .header {
        align-items: flex-start;
    }

    .btn-back, .btn-add {
        margin-bottom: 10px;
    }
    .btn-edit, .btn-delete, .btn-play {
        font-size: 10px;
        width: auto;
        white-space: nowrap;
    }

    .slideshow-name {
        font-size: 15px;
    }
}
