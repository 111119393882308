/* EditSlideshowModal.css */

.edit-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.slide-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}

.slide-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.slide-item span {
    font-weight: bold;
    margin-right: 10px;
}

.slide-item button {
    margin-left: 10px;
}

.slide-item video {
    width: 50px; /* Adjust as per your requirement */
    height: 50px; /* Adjust as per your requirement */
    object-fit: cover;
    margin-right: 10px;
}

.slide-container {
    max-height: 75vh;
    overflow-y: auto;
    padding: 10px; /* optional: for some spacing */
    border: 1px solid #ccc; /* optional: to visually see the container */
    background-color: black;
    border-radius: 10px;
}

.slideshow-modal-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    margin-right: 10px;
}

.slideshow-modal-button:last-child {
    margin-right: 0;
}


.slideshow-modal-button.save:hover {
    background-color: #43a047;
}

.slideshow-modal-button.close:hover {
    background-color: #b13d7b;
}


.slideshow-modal-button.add-slide:hover {
    background-color: #1c7ed6;
}

/* Footer container for the buttons */
.modal-footer {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    margin-top: 15px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.slideshow-modal-button.add-slide {
    padding: 8px 12px;
    margin-left: 10px;
    background-color: #007BFF;  /* or your preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.slideshow-modal-button.save {
    padding: 8px 12px;
    background-color: #28a745;  /* or your preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.slideshow-modal-button.close {
    padding: 8px 12px;
    background-color: #dc3545;  /* or your preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.input-group input{
    padding: 5px;
    border-radius: 5px;
}

.single-digit input{
    max-width: 50px;
}

.modal-content {
    width:60vh;
    height: 100vh;
}

@media screen and (max-width: 800px) {
    .edit-modal {
        width: 100%;
    }
    .edit-modal button:not(.btn-close, .remove-button, .slideshow-modal-button.close, .slideshow-modal-button.save, .slideshow-modal-button.add-slide) {
        padding: 2px;
        gap: 2px;
        margin: 2px;
    }
    .slide-duration {
        font-size: 10px;
    }
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column; /* Stacks spinner and text vertically */
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    text-align: center;
    z-index: 9999;
}

.loading-modal-spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    display: inline-block;
}

.loading-text {
    margin-top: 20px; /* Adds space between the spinner and text */
    color: #ffffff; /* Makes the text white */
    font-weight: bold; /* Makes the text bold */
    font-size: 18px; /* Adjust the size as needed */
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
