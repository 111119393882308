.function-select-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    background-size: cover;
}

.function-option {
    display: flex;
    justify-content: flex-start; /* Align content to the start (top) */
    align-items: center;
    flex-direction: column; /* Stack items vertically */
    width: 45%; /* Adjust based on preference */
    height: 70%; /* Adjust based on preference */
    margin: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding-top: 30px; /* Add padding at the top */
}

.function-option h2 {
    color: white;
    font-size: 36px; /* Larger text for TV screens */
    text-align: center;
    margin-top: 0; /* Remove top margin for the heading */
}

.slideshow-option {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/ScreenImg.png');
    background-size: cover;
    background-position: center;
}

.queue-option {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/QueueImg.png'); /* Replace with actual image URL */
    background-size: cover;
    background-position: center;
}

.function-option:hover {
    transform: scale(1.1); /* Slight zoom on hover for feedback */
}
