.loading-wave {
    width: 100px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .loading-bar {
    width: 5px;
    height: 10px;
    margin: 0 2px;
    background-color: #3498db;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    animation-delay: 0.3s;
  }

  

.manual-deal {
    padding: 5px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 1.2em;
}
  
  @keyframes loading-wave-animation {
    0% {
      height: 2px;
    }
  
    50% {
      height: 15px;
    }
  
    100% {
      height: 2px;
    }
  }