.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* This makes the background dark and slightly transparent */

}

.center-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-modal-content {
    background-color: white;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    border-radius: 5px;
}

.input-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.input-row label {
    flex: 0 0 200px;  /* This gives the label a fixed width */
    text-align: right;
    margin-right: 10px; /* This creates some spacing between the label and the input */
}

.color-preview {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 1px solid #ccc;
}

.edit-modal {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f4f4f4;
}

.edit-modal h2 {
    color: #333;
    margin-bottom: 20px;
}

.input-row {
    margin-bottom: 15px;
}

.input-row label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.input-row input[type="text"],
.input-row input[type="file"] {
    width: auto;
    padding: 8px;
    margin: 5px 0 20px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.input-row input[type="file"] {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
}

.edit-modal button:not(.btn-close, .remove-button, .slideshow-modal-button.close, .slideshow-modal-button.save, .slideshow-modal-button.add-slide) {
    background-color: #68f60c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    margin-right: 5px;
}

.edit-modal button:not(.btn-close, .remove-button, .slideshow-modal-button.close, .slideshow-modal-button.save, .slideshow-modal-button.add-slide):hover {
    background-color: #68f60c;
}
