.fileselection-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.fileselection-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.fileselection-modal-content {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    z-index: 1001;
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
}
.fileselection-modal-content h2 {
    margin-bottom: 40px;
}

.fileselection-scroll-container {
    max-height: 300px;
    overflow-y: auto;
}

.fileselection-file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.fileselection-file-card {
    width: 150px;
    height: 150px;
    background-color: #f0f0f0;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileselection-file-card:hover {
    transform: scale(1.05);
}

.fileselection-image-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.fileselection-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.fileselection-file-card-placeholder {
    font-size: 40px;
    color: #ccc;
}


.fileselection-remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.fileselection-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.fileselection-modal-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.fileselection-modal-button:hover {
    background-color: #0056b3;
}

.fileselection-selected {
    border: 8px solid #007bff; /* Blue border for selected images */
}

.fileselection-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.fileselection-file-name {
    font-size: 16px;
    color: #333;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
}

.fileselection-video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.fileselection-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Text shadow for readability */
}

.fileselection-file-name-overlay {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8); /* Inverted background */
    padding: 4px 8px;
    border-radius: 4px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 8px;
    text-align: center;
}

.no-files-message {
    color: red;
    margin-left: 50px;
    font-size: 20px;
    animation: fadeOut 6s forwards; /* 3s for the fade-out */
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.loading-spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

