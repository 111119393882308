/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=Arvo&family=Bebas+Neue&family=Bitter&family=Bookman&family=Brush+Script+MT&family=Cabin&family=Caveat&family=Courier+New&family=Crimson+Text&family=Dancing+Script&family=Dosis&family=Exo+2&family=Fira+Sans&family=Garamond&family=Georgia&family=Helvetica&family=Impact&family=Inconsolata&family=Josefin+Sans&family=Lato&family=Lobster&family=Merriweather&family=Montserrat&family=Muli&family=Nanum+Gothic&family=Noto+Sans&family=Open+Sans&family=Oswald&family=Pacifico&family=Palatino&family=Playfair+Display&family=PT+Sans&family=Quicksand&family=Raleway&family=Roboto&family=Slabo+27px&family=Times+New+Roman&family=Trebuchet+MS&family=Ubuntu&family=Varela+Round&family=Verdana&family=Work+Sans&family=Zilla+Slab&display=swap');

#font-selector {
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

#font-selector option {
    font-size: 16px;
}

.font-selector-container {
    margin-top: 20px;
}

/* Apply font styles to each font option */
.Abril-Fatface { font-family: 'Abril Fatface', cursive; }
.Anton { font-family: 'Anton', sans-serif; }
.Arvo { font-family: 'Arvo', serif; }
.Bebas-Neue { font-family: 'Bebas Neue', cursive; }
.Bitter { font-family: 'Bitter', serif; }
.Bookman { font-family: 'Bookman', serif; }
.Brush-Script-MT { font-family: 'Brush Script MT', cursive; }
.Cabin { font-family: 'Cabin', sans-serif; }
.Caveat { font-family: 'Caveat', cursive; }
.Courier-New { font-family: 'Courier New', monospace; }
.Crimson-Text { font-family: 'Crimson Text', serif; }
.Dancing-Script { font-family: 'Dancing Script', cursive; }
.Dosis { font-family: 'Dosis', sans-serif; }
.Exo-2 { font-family: 'Exo 2', sans-serif; }
.Fira-Sans { font-family: 'Fira Sans', sans-serif; }
.Garamond { font-family: 'Garamond', serif; }
.Georgia { font-family: 'Georgia', serif; }
.Helvetica { font-family: 'Helvetica', sans-serif; }
.Impact { font-family: 'Impact', sans-serif; }
.Inconsolata { font-family: 'Inconsolata', monospace; }
.Josefin-Sans { font-family: 'Josefin Sans', sans-serif; }
.Lato { font-family: 'Lato', sans-serif; }
.Lobster { font-family: 'Lobster', cursive; }
.Merriweather { font-family: 'Merriweather', serif; }
.Montserrat { font-family: 'Montserrat', sans-serif; }
.Muli { font-family: 'Muli', sans-serif; }
.Nanum-Gothic { font-family: 'Nanum Gothic', sans-serif; }
.Noto-Sans { font-family: 'Noto Sans', sans-serif; }
.Open-Sans { font-family: 'Open Sans', sans-serif; }
.Oswald { font-family: 'Oswald', sans-serif; }
.Pacifico { font-family: 'Pacifico', cursive; }
.Palatino { font-family: 'Palatino', serif; }
.Playfair-Display { font-family: 'Playfair Display', serif; }
.PT-Sans { font-family: 'PT Sans', sans-serif; }
.Quicksand { font-family: 'Quicksand', sans-serif; }
.Raleway { font-family: 'Raleway', sans-serif; }
.Roboto { font-family: 'Roboto', sans-serif; }
.Slabo-27px { font-family: 'Slabo 27px', serif; }
.Times-New-Roman { font-family: 'Times New Roman', serif; }
.Trebuchet-MS { font-family: 'Trebuchet MS', sans-serif; }
.Ubuntu { font-family: 'Ubuntu', sans-serif; }
.Varela-Round { font-family: 'Varela Round', sans-serif; }
.Verdana { font-family: 'Verdana', sans-serif; }
.Work-Sans { font-family: 'Work Sans', sans-serif; }
.Zilla-Slab { font-family: 'Zilla Slab', serif; }
