.carousel-container {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin: auto;
  }
  
  .carousel-container img {
    width: 100%;
    border-radius: 10px; /* Optional: for rounded corners */
  }
  