body {
    overflow: auto;
}

.home-container {
  min-height: 100vh;
  overflow-y: visible; /* Ensure overflow is not set to hidden */
}
  
  .background-image-container {
    width: 100%;
    height: calc(100vh - 55px);
    overflow: hidden;
  }
  
  .background-image-container img {
    width: 100%;
    height: 100%;
    
    object-fit: cover;
  }
  
  .content {
    position: relative;
  }
  
  .bubbles-section {
    padding: 20px;
    background-color: black;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/HeaderImg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;

  }
  
  .bubble-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
  }
  
  .left .circle, .right .circle {
    flex-shrink: 0; /* Prevent the circle from shrinking */
    width: 20%;
    height: 20%;
  }
  
  .left .text-container, .right .text-container {
    max-width: 60%;
    padding:30px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .left .circle {
    order: 1; /* Circle comes first on the left side */
  }
  
  .left .text-container {
    order: 2; /* Text comes second on the left side */
    padding-left: 40px;
    margin-left: -30px;
  }
  
  .right .circle {
    order: 2; /* Circle comes second on the right side */
  }
  
  .right .text-container {
    order: 1; /* Text comes first on the right side */
    padding-right: 40px;
    margin-right: -30px;
  }
  
  .circle {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .circle img {
    width: 100%;
    height: auto;
  }
  
  .text-container {
    z-index: 0;
  }
  
  .bigger-text {
    margin-top: 0;
    color: white;
    font-size: 2em;
  }
  
  ul {
    padding-left: 20px;
    text-align: left;
  }
  
  .bullets {
    margin-bottom: 5px;
    color: white;
    font-size: 1.2em;
  }

  .homeimageslider {
    border-radius: 5px;
  }

  .frontend-slide-container {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    padding: 40px;
    display: flex;
  }

  .frontend-slide-container-black {
    background-color: black;
    padding: 40px;
    display: flex;
    border: 10px solid grey;
  }

  .centered-text-container {
    width: 50%;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 5%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .centered-text-container-left {
    width: 50%;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: 5%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.mission-logo-container img{
    width: 100vw;
    background-color: black;
    
}

.mission-logo-section {
    background-color: black;
}

.mission-statement, .vision-statement {
    color:white;
    opacity: 0;
    text-align: center;
    font-weight: bold;
    
}

.vision-statement {
    padding-top: 0px;
    font-weight: bold;
    font-size: 1.2em;
}

@media screen and (max-width: 800px) {
  .bigger-text{
    font-size: 18px;
  }

  .bullets {
    font-size: 12px;
  }

  .text-container {
    max-width: 80%;
    width: 80%;
    padding: 5px;
  }

  .right .text-container {
    padding:8px;
    padding-right: 30px;
  }

  .left .text-container {
    padding:8px;
    padding-left: 40px;
  }

  .left .circle, .right .circle {
    flex-shrink: 0; /* Prevent the circle from shrinking */
    width: 30%;
    height: 30%;
  }

  .bubble-container {
    margin-bottom: 20px;
  }

  .frontend-slide-container, .frontend-slide-container-black {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-plans {
    flex-direction: column;
  }

  .bubbles-section {
    max-height: 100%;
    min-height: 100%;
  }


}


@media screen and (max-width: 1500px) {

  #mc_embed_signup {
    width: 100%;
  }
}

.deck-image {
  display: block;
}

@media screen and (max-width: 800px) {
  .deck-image {
    display: block;
    
  }
  .deck-image img {
    border: 10px solid #fff;
    width: 100%;
    margin-bottom: 20px;
  }
}



.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #ffffff;
	border-radius: 50%;
	position: relative;
  z-index: 4;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #ffffff;
  	border-bottom: 2px solid #ffffff;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.home-arrow {
	width: 0;
	height: 40px;
	border: 1px solid #333;
	position: relative;
	animation: scroll 1.5s infinite;
	-webkit-animation: scroll 1.5s infinite;
	&::after {
		content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -5px;
    width: 1px;
    height: 10px;
		
    border-top: 10px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	}
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

.down-container {
  width: 100%;
	height: auto;
  margin-top: -150px;
	background-color: #c9d6df;
	display: flex;
	align-items: center;
	justify-content: center;
  z-index: 2;
}


.home-client-logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  background-color: black;
  border-radius: 10px;
}

.home-client-logo {
  width: 150px;
  max-width: 15%;
  height: auto;
  filter: brightness(0) invert(1); /* Makes logos appear white */
}

@media screen and (max-width: 800px) {
  .home-client-logo {
    max-width: 30%; /* Adjust size for smaller screens */
  }
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.home-logo-container img {
  width: 150px;
  height: auto;
}

.home-login-button {
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 0.92em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.home-login-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}




.home-customer-feedback {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  margin: 50px 0;
}

.feedback-container {
  max-width: 100%;
  display: flex;
  flex-direction: column; /* Default to stack vertically */
  align-items: center;
  padding: 20px;
  gap: 20px; /* Adds space between image and text container */
}

.feedback-image {
  width: 100%;
  max-width: 50vw;
  aspect-ratio: 1;
  border-radius: 20px;
  object-fit: cover;
}

.feedback-text {
  display: flex;
  flex-direction: column; /* Stacks quote, name, and title vertically */
  align-items: center;
  text-align: center; /* Center-align text */
}

.feedback-quote {
  font-size: 2em;
  font-weight: bold;
  color: rgb(119, 119, 119);
  margin: 20px 0;
  max-width: 80%;
  line-height: 1.4;
}

.feedback-name {
  font-weight: bold;
  font-size: 1.2em;
  color: #a0a0a0;
  margin-top: 10px;
}

.feedback-title {
  font-size: 1em;
  color: #8c8c8c;
}

/* Larger screens */
@media screen and (min-width: 800px) {
  .feedback-container {
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
    text-align: left;
  }

  .feedback-image {
    max-width: 250px;
  }

  .feedback-text {
    align-items: flex-start; /* Align text to the left */
    text-align: left;
  }
}

/* Smaller screens */
@media screen and (max-width: 800px) {
  .feedback-quote {
    font-size: 1.5em;
  }

  .feedback-image {
    max-width: 100%;
  }
  .home-logo-container img {
    width: 120px;
    height: auto;
  }
}





.homepage-form-container {
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
}

.homepage-form-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-items: center; /* Centers vertically */
  justify-content: center; /* Centers horizontally */
}

.animated-frame {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: inherit;
  overflow: hidden;
z-index: 2;
}

.overlay-text {
  position: absolute;
  right: 10%; /* Adjust for desired right-side position */
  top: 50%;
  transform: translate(-50%, -50%); /* Center vertically */
  text-align: center;
  color: white;
  font-size: 3em; /* Adjust font size as needed */
  line-height: 1.2;
  font-weight: bold;
  transform: rotateY(0);
  perspective: 100px;
  z-index: 5;
}

.overlay-text p {
  margin: 0;
  color: white;
  transform-style: preserve-3d;
  transform: rotateY(0);
}

@media screen and (max-width: 768px) {
  .overlay-text {
    right: 5%; /* Adjust for mobile */
    font-size: 2em; /* Scale down text size for smaller screens */
  }
}


@media screen and (max-width: 1100px) {

  .background-image-container img {
    width: 100%;
    height: 100%;
    
    object-fit: contain;
    transform: scale(1.5); 
  }

  .BackgroundTopHalf, .BackgroundBottomHalf {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1; /* Adjust to place these layers above the image but behind main content */
  }
  
  .BackgroundTopHalf {
    top: 0;
    background-color: black;
  }
  
  .BackgroundBottomHalf {
    top: 50%; /* Ensures it starts where BackgroundTopHalf ends */
    background-color: #A8ADA5;
  }

}

/* Small screen overlay text at the top */
.small-screen-top-text {
  display: none;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  transform-origin: center;
  z-index: 5;
}

/* Small screen overlay text at the bottom */
.small-screen-bottom-text {
  display: none;
  position: absolute;
  bottom: 13%;
  left: 25%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.5em;
  text-align: center;
  z-index: 5;

}

/* Only show on smaller screens */
@media screen and (max-width: 1100px) {
  .small-screen-top-text,
  .small-screen-bottom-text {
    display: block;
  }
  .small-screen-bottom-text {
    bottom: 14%;
    left: 28%;
  }
  .overlay-text {
    opacity: 0;
  }
}

@media screen and (max-width: 760px) {
  .small-screen-top-text,
  .small-screen-bottom-text {
    display: block;
  }
  .small-screen-bottom-text {
    bottom: 24%;
    left: 50%;
    font-size: 1em;
  }
  
}



/* Footer Styling */
.home-footer {
  background-color: black;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.home-footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}

.home-footer-icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  transition: transform 0.3s ease;
}

.home-footer-icon-circle:hover {
  transform: scale(1.1);
}

.home-footer-icon-circle i {
  font-size: 1.2em;
}

.home-footer-copyright {
  font-size: 0.9em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .home-footer-icons {
    gap: 15px;
  }
  
  .home-footer-icon-circle {
    width: 35px;
    height: 35px;
  }

  .home-footer-copyright {
    font-size: 0.8em;
  }
}




/* Banner Section */
.home-banner {
  background-color: white;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.home-banner-heading {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  color: black;
}

.home-banner-subtext {
  font-size: 1.2em;
  margin: 10px 0;
  color: #555;
}

.home-banner-button {
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-banner-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 768px) {
  .home-banner-heading {
    font-size: 2.5em;
  }

  .home-banner-subtext {
    font-size: 1.5em;
  }

  .home-banner-button {
    font-size: 1.2em;
    padding: 8px 16px;
  }
  .scale-down {
    max-width: 60%!important;
    padding: 30px;
    margin-top: 20px;
  }
}


.scale-down {
  max-width: 20%;
  padding: 30px;
  margin-top: 20px;
}




.secondary-header {
  position: relative; /* Default inline position */
  background-color: #68F60C; /* Green background */
  color: black; /* Black text */
  text-align: center;
  padding: 0px;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; /* Smooth transition for stickiness */
  z-index: 10;
}

.secondary-header p {
  margin: 0;
  flex: 1;
  text-align: left;
  padding-left: 15px;
  font-weight: bold;
}

.secondary-header button {
  background-color: black; /* Black button */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 15px;
  min-width: 150px;
  transition: background-color 0.3s ease;
}

.secondary-header button:hover {
  background-color: #333; /* Slightly lighter black on hover */
}

.secondary-header.sticky {
  position: fixed; /* Sticky behavior */
  top: 51px; /* Adjust to match the primary header's height */
  width: 100%; /* Ensure it spans the full width */
  z-index: 15; /* Place it above other elements */
}



.secondary-header .Marquee {
  width: 100%; /* Ensure it spans the full width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
}

.secondary-header .Marquee span {
  display: inline-block; /* Ensure text remains inline */
  padding-left: 10px; /* Space between repeated texts */
}