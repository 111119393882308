.deal-sheet-visualization-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.deal-sheet-header {
    width: 100vw;
    overflow: hidden; /* Optional: in case the image is larger than the div */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center the header */
}

.deal-sheet-header img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.deal-sheet-visualization {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    max-width: 100vw;
    margin: 0 auto;
    max-height: calc(100vh - 100px); /* Subtracting the height of the header */
    overflow-y: auto;  /* Make it scrollable when content is overflowed */
}

ul {
    padding-left: 0 !important;
}



.category-section {
        border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    margin: 10px;
    
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px); 
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    }



.category-section {
    /* FIRE SECOND OPTION FOR CATS
    border: 1px solid rgba(255, 255, 255, 0.1); 
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    margin: 10px;

    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    */
}

.cat-names {
    border-radius: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 10px;
    color: #ffd900;
    box-shadow: 0 8px 5px rgba(0, 0, 0, 0.2);
    /*text-shadow: 0 0 2px #cccccc, 0 0 5px #cccccc, 0 0 2px #cccccc; White glow effect */
}

.deal {
    display: flex;
    align-items: center;
    color: white
}

.deal-info, .deal-discount {
    flex-shrink: 0; /* Prevent shrinking */
    font-weight: bold; /* Make deals bold */
}

.deal-divider {
    flex-grow: 1;
    border-bottom: 1px dashed rgb(255, 255, 255);
    margin: 0 10px;
    align-self: center;
}

.deal-info-line {
    display: block; /* Ensure each line is on a new line */
    /* Add any other necessary styling here */
    font-weight: bold;
}

.dealsheet-deals-list {
    text-align: center;
}

@media (max-width: 70vw) {
    .deal-sheet-visualization {
        max-width: 100%;
    }
}
