.actual-margin-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.actual-margin-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    text-align: left;
    position: relative; /* Ensure close button can be positioned */
}

.actual-product-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/* New container for image and price/cost stacked vertically */
.actual-image-price-cost {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.actual-product-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px; /* Add space between image and price/cost */
}

.actual-product-info h3 {
    margin: 0;
    font-size: 1.5rem;
}

.actual-product-info p {
    margin: 5px 0;
}

.actual-product-info {
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Price and Cost section (stacked vertically now) */
.actual-price-cost {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.actual-price, .actual-cost {
    font-size: 1.2rem;
    font-weight: bold;
}

/* Description section */
.actual-description {
    font-size: 1rem;
    margin-bottom: 10px;
    padding: 20px;
}

/* SKU and Category */
.actual-category {
    font-style: italic;
    color: #555;
}

.actual-margin-info {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.actual-margin-info p {
    margin: 5px 0;
}

.actual-margin {
    font-weight: bold;
    font-size: 1.2rem;
}

/* Action button for margin calculations inside the margin box */
.actual-margin-button-container {
    margin-top: 10px;
}

.actual-margin-button-container button {
    margin-top: 10px;
}

/* Close button styling */
.actual-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
}
