.health-report-background {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG4.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 100vh; /* At least as tall as the viewport */
    height: auto;
    padding-bottom: 100px;
}

.metrics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.metric {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 10px;
    min-width: 250px;
    text-align: center; /* Center content horizontally */
    background-color: black;
    flex-basis: calc(50% - 100px); /* Adjust width to fit two items per row */
}

.metric .data {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.metric .data > div {
    width: 50%;
}

.last-week, .this-week {
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
}

.last-week {
    background-color: #a8a8a8; /* Slightly darker for last week */
}

.number {
    font-weight: bold;
    font-size: 4em;
}

.change {
    font-weight: bold;
    font-size: 1.5em;
}

/* Styles for a single metric row */
.single-metric-row {
    display: flex;
    justify-content: center;
}

/* Styles for a double metric row */
.double-metric-row {
    display: flex;
    justify-content: space-between;
}

.loading-data {
    color: white;
}

.white {
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 10px;
}

.reportHeader {
    background-color: black;
}

.cardTitle {
    color: white;
}


/* Media query for small screens */
@media screen and (max-width: 1200px) {
    .number {
        font-weight: bold;
        font-size: 2em;
    }
    
    .change {
        font-weight: bold;
        font-size: 1.2em;
    }
}

/* Media query for small screens */
@media screen and (max-width: 800px) {
    .number {
        font-weight: bold;
        font-size: 1em;
    }
    
    .change {
        font-weight: bold;
        font-size: 1em;
    }
}