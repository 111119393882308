* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.queue-background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
}

.next-in-line-header {
    padding-top: 0%;
    font-size: 65px;
    /*color: white;*/
    text-align: center;
}

.first-person {
    text-align: center;
    font-size: 8vmax; /* Adjust based on your preference */
    /*color: white;*/
    margin-bottom: 20px; /* Space between the first person and the remaining names */
    font-weight: bold;
}

.remaining-names {
    text-align: center;
    font-size: 5vmax; /* Adjust based on your preference */
    /*color: grey;   Or any other color you prefer */
    font-weight: bold;
}

.queue-logo-container {
    padding-top: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Adjust based on your preference */
    flex-direction: column-reverse;
}

.queue-store-logo {
    max-width: 30%; /* Ensure the logo fits within the container */
    height: auto; /* Maintain the aspect ratio */
}

@media (max-width: 1500px) {

    .next-in-line-header {
        font-size: 60px;
    }
}

@media (max-height: 600px) {
    .first-person {
        font-size: 15vw;
    }
    .remaining-names {
        font-size: 5vw;
    }
    .next-in-line-header {
        font-size: 50px;
    }
}

@media (max-width: 1000px) {
    .next-in-line-header {
        font-size: 50px;
    }
}
