.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: white;
}

.plans {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
    color: white;
}

.plan {
    border: 1px solid #e0e0e0;
    padding: 20px;
    flex: 1;
    margin: 0 10px;
    text-align: center;
    border-radius: 10px;
    background-color: black;
}

.header-plan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feature-table {
    width: 100%;
    border-collapse: separate; 
    background-color: black;
    color: white;
}

.feature-table th, .feature-table td {
    border: 1px solid #e0e0e0;
    padding: 10px;
    text-align: center;
}

table {
    border-collapse: separate; 
    border-spacing: 0;
    border: 2px solid #333;
    border-radius: 10px; 
    overflow: hidden;
    font-size: 1.5em;
}

table th, table td {
    border: 2px solid #333;
    border-width: 0 0 1px 1px;
    padding: 10px;
    width: 25%;
}

table th:last-child, table td:last-child {
    border-right: 0;
}

table tr:last-child td {
    border-bottom: 0;
}

.PriceInfoPrice {
    font-weight: bold;
    font-size: 500%;
}

/* Style for the checkmarks */
.feature-yes {
    color: green;
    font-weight: bold;
}

/* Style for the X's */
.feature-no {
    color: red;
    font-weight: bold;
}

.scrolltainer {
    overflow-y: auto;
    background-color: black;
}

.greenmind-btn {
    padding: 10px 20px;
    border: none;
    margin: 5px 5px;
    width: 30vw;
    max-width: 150px;
    background-color: #68f60c; /* green color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    border-radius: 5px; /* Add rounded corners */
}