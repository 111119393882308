.qr-code-manager {
    position: relative;
    padding: 20px;
    text-align: center;
    background-color: black;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0px auto;
    box-shadow: 0 0 10px 2px #68f60c;
    height: 100vh;
    
}

.create-form {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.qr-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    flex: 1.6;
}

.qr-button {
    padding: 10px 20px;
    border: none;
    background-color: #68f60c;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
    flex: 1;
}

.top-space {
    margin-top: 20px;
}

.qr-button:hover {
    background-color: #45a049;
}

.qr-codes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
    overflow-y: auto;
    padding: 10px;
    max-height: 60vh; /* Adjust as needed */
    
}

.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px 2px #68f60c;
    position: relative;
    min-width: 228px;
    min-height: 228px;
}

.qr-code-container:hover {
    box-shadow: 0 0 15px 3px #68f60c;
}

.qr-code {
    cursor: pointer;
    margin-bottom: 10px;
    transition: transform 0.2s;
}

.qr-code:hover {
    transform: scale(1.05);
}

.edit-form, .qr-code-info {
    text-align: center;
}

.qr-code-info button {
    background-color: #0056b8;
}

.qr-code-info button:hover {
    background-color: #004080;
}

.QR-Page-Title {
    color: white;
    margin-bottom: 20px;
}

.background-wrapper {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG2.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}

.btn-delete-qr {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #D15091;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px;
  }
  
  .btn-delete-qr:hover {
    background-color: #B03060;
  }

  .btn-exit {
    position: absolute; /* Position the button absolutely within its relative parent */
    top: 10px; /* 10px from the top */
    left: 10px; /* 10px from the left */
    padding: 10px 20px;
    border: 3px solid white; /* 3px white border */
    background-color: black;
    color: white;
    font-size: 16px;
    border-radius: 10px; /* 10px rounded corners */
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-exit:hover {
    background-color: #333; /* Slightly lighter black on hover */
}

/* Initially hide the download icon */
.qr-download-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(0, 217, 255);
    font-size: 48px; /* Adjust the size as needed */
    pointer-events: none; /* So it doesn't interfere with any clicks on the QR code */
    z-index: 1; /* Place it above the QR code */
}

/* Display the download icon when hovering over the qr-code-container */
.qr-code:hover .qr-download-icon {
    display: block;
}

.qr-button {
    max-height: 40px;
}

.qr-link {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px; /* Adjust the width as necessary */
    display: inline-block;
  }