.dutchie-modal-content {
    z-index: 2000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;           /* half of the screen width */
    max-height: 80vh;
    min-width: 500px;     /* minimum width of 500px */
    background-color: white;  /* white background */
    border-radius: 10px;  /* optional: rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);  /* optional: a subtle shadow for depth */
    padding: 20px;        /* optional: some padding for inner content */
    /* ... any other required styles ... */
}

.scrollable-deals-list {
    max-height: 60vh; /* Adjust as needed to ensure the container doesn't occupy the entire height */
    overflow-y: auto;
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dutchie-modal-background {
    z-index: 1999;  /* This should be just below the z-index of .dutchie-modal-content */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent black */
}

.selected-counter {
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;  /* Add flexbox */
    align-items: center;  /* Align items vertically in the center */
    justify-content: space-between;  /* Space out the items */
}

.deals-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.deal-item {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    padding:5px;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.btn-save, .btn-close {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

}

.btn-save {
    background-color: #68f60c; /* green color */
    color: white;
}

.btn-save:hover {
    background-color: #43a047; /* a shade darker on hover */
}

.btn-close {
    background-color: #D15091; /* red color */
    color: white;
}

.btn-close:hover {
    background-color: #b13d7b; /* a shade darker on hover */
}


.category-search {
    position: relative;
    width: 100%;
}

.category-item {
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s;
}

.category-item:hover {
    background-color: #f7f7f7;
}

/* This styles the dropdown container */
.dropdown-container {
    position: absolute;
    top: 100%;  /* Position it right below the search input */
    left: 0;
    width: auto;
    max-height: 200px;  /* Adjust as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;  /* Ensures the dropdown is on top */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Optional: Adds a slight shadow for depth */
}

.category-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}


.deal-search {
    display: inline-block;
    margin-left: 0;  /* Remove the left margin */
}

.deal-search {
    display: inline-block;
    margin-left: 0;  /* Remove the left margin */
}

.search-row {
    display: flex;
    gap: 10px; /* This creates a 10 pixel gap between the two search boxes */
    align-items: center;
}

.deal-search, .category-search {
    flex: 1;  /* Give equal width to both search boxes */
    margin: 0 5px;  /* Optional: Give some margin to space them out a bit */
}


.dutchiedealsboxes {
    transform: scale(1.5);
    margin: 5px;
}

.dutchiedealinput {
    padding:10px;
    border-radius: 5px;

}

@media screen and (max-width: 800px) {
    .dutchie-modal-content {
        width: 100%;
        min-width: 100% !important;
    }

    .selected-counter {
        display: block;
    }

    .search-row {
        gap: 2px;
    }
}