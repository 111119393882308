.appdevice-new-device-input {
    margin-bottom: 20px;
}

.appdevice-scrollable-box {
    max-height: calc(100vh - 260px); /* Adjust based on other elements' heights */
    overflow-y: auto;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appdevice-app-devices-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alt-color {
    background-color: #e8ff74 !important;
}

.appdevice-device-item-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.appdevice-device-item {
    width: 100%;
    max-width: 600px; /* Adjust as needed */
    margin-bottom: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the left */
}

.appdevice-wholeDeviceAppPageContainer {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG4.png');
    background-size: cover;
    background-position: 0, -50px;
}

.appdevice-appdevicepageedits {
    padding-top: 10px;
    outline: 5px solid #68f60c;
}

/* Reusing styles from SlideshowsPage.css for consistency */

.appdevice-btn-add, .appdevice-btn-select, .appdevice-input-field, .appdevice-btn-select-slideshow, .appdevice-btn-delete {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    border-radius: 5px;
}

.appdevice-btn-add {
    background-color: #68f60c;
}

.appdevice-btn-add:hover {
    background-color: #58c40a;
}

.appdevice-btn-select {
    background-color: #0cf2f6;
    white-space: nowrap;
    min-width: 200px; /* Device Type Selector - Green */
}

.appdevice-btn-select:hover {
    background-color: #02bec2e4;
}

.appdevice-btn-select-slideshow {
    background-color: #7061AC; /* Slideshow Selection - Purple */
}

.appdevice-btn-select-slideshow:hover {
    background-color: #57487D; /* Darker shade on hover */
}

.appdevice-btn-delete {
    background-color: #D15091; /* Delete Button - Previous Slideshow color */
}

.appdevice-btn-delete:hover {
    background-color: #B03C79; /* Darker shade on hover */
}

.appdevice-input-field {
    background-color: white;
    color: black;
}

/* StoreSelectionPage.css */

.appdevice-store-selection-page2 {
    text-align: center;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px; /* Padding at the top */
    padding-bottom: 60px; /* Padding at the bottom */
}

.appdevice-select-title {
    margin-bottom: 40px; /* Margin for the title */
    font-size: 36px;
    color: #fff;
}

.appdevice-tv-wrapper {
    background-color: rgba(0,0,0,0.7);
    border-radius: 20px;
    padding: 20px; /* Added padding to ensure spacing within the box */
    width: 90%; /* Ensure the wrapper doesn't take full width */
    max-width: 1200px; /* Ensure the wrapper doesn't exceed a max width */
    box-sizing: border-box;
}

.appdevice-device-name-input {
    padding: 10px;
    margin: 10px;
    background-color: black;
    color: white;
}

.appdevice-vertical-button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons horizontally */
    gap: 20px; /* Space between buttons */
}
