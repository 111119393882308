/* Settings.css */

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.settings-div-container {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    overflow: hidden;
}

.settings-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.logo-div {
    padding: 0px;
    display: flex;
    margin-top: 0;
}

.logo-div img {
    padding: 0px;
    margin-top: 0px;
    height: auto;
    z-index: 0;
}

.settings-logo {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    max-width: 400px;
    width: 100%;
}

.settings-buttons-div {
    padding: 2px;
    display: flex;
    align-items: right;
    margin-top: 20px;
    margin-right: 35px;
    gap: 10px;
}

.settings-buttons-div button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out; /* Smooth transition for button hover */
}

.settings-buttons-div button:hover {
    background-color: #388e3c; /* A slightly darker green on hover */
}

.settings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: calc(100vh - 90px);
    gap: 25px;
    padding: 35px;
    box-sizing: border-box;
}

.settings-quarter-div {
    display: flex;
    background-color: #d12c31;
    padding: 3px;
    box-shadow: 0px 0px 15px black;
    box-sizing: border-box;
    transition: filter 0.3s;
    border-radius: 10px;
}

.settings-quarter-div:hover {
    filter: brightness(1.2);
}

/* You can replace these with different images and their respective paths */
.settings-inset-div1, .settings-inset-div2, .settings-inset-div3, .settings-inset-div4 {
    background-repeat: no-repeat;
    background-color: black;
    background-size: 40vw, 40vh;
    background-position: center center;
    flex-grow: 1;
    box-shadow: inset 0px 0px 10px black;
    color: white;
    font-size: 20pt;
    display: inline-block;
    border-radius: 10px;
}

.settings-btnlabel {
    display: inline-block;
    color: white;
    font-size: 20pt;
    position: relative;
    top: 82%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-shadow: 
        0 0 3px #000, 
        0 0 6px #000, 
        0 0 9px #000, 
        0 0 12px #000, 
        0 0 15px #000;
    background-color: rgba(0, 0, 0, 0); /* 30% opaque black background */
    padding: 5px 10px; /* Some padding around the text for better visual effect */
    border-radius: 5px;
}

.minibtnlabel {
  display: inline-block;
    color: white;
    font-size: 20pt;
    position: relative;
    top: 82%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-shadow: 
        0 0 3px #000, 
        0 0 6px #000, 
        0 0 9px #000, 
        0 0 12px #000, 
        0 0 15px #000;
    background-color: rgba(0, 0, 0, 0); /* 30% opaque black background */
    padding: 5px 10px; /* Some padding around the text for better visual effect */
    border-radius: 5px;
}

.user-name {
    display: inline;
    margin: 0;
    padding: 0;
    position: relative;
    top: 8%;
    left: 45%;
    transform: translateX(-50%);
    color: white;
    font-size: 16pt;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 12;
    border: 1px solid white;
    background-color: black;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown div {
    color: white;
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown div:hover {
    background-color: #555;
}

/* Media query for small screens */
@media screen and (max-width: 1200px) {
  .settings-container {
    flex-direction: column;
    margin-bottom: 10px; /* Add margin between buttons on small screens */
  }

  .settings-buttons-div {
    margin-top: 40px;
    margin-right: 0px;
  }

  .dashboard-container {
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .logo-div img {
    max-width: 200px;
  }

  .settings-btnlabel {
    display: inline-block;
    color: white;
    font-size: 15pt;
    position: relative;
    top: 82%;   /* Distance from the bottom of the button */
    left: 50%;      /* Center the label horizontally */
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8); /* 30% opaque black background */

  }
  .settings-inset-div1, .settings-inset-div2, .settings-inset-div3, .settings-inset-div4 {
    background-repeat: no-repeat;
    background-color: black;
    background-position: center center;
    flex-grow: 1;
    box-shadow: inset 0px 0px 10px black;
    color: white;
    font-size: 20pt;
    display: inline-block;
  }
  
  .user-name {
    display: none;
  }
}
@media screen and (max-width: 560px) {
  .settings-btnlabel {
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 10pt;
    position: relative;
    top: 72%;   /* Distance from the bottom of the button */
    left: 50%;      /* Center the label horizontally */
    transform: translateX(-50%);

  }
  .dashboard-container {
    padding-top: 0px;
    padding-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
  }
  .settings-inset-div1, .settings-inset-div2, .settings-inset-div3, .settings-inset-div4 {
        background-size: 70vw, 70vh;
    }

}

@media screen and (max-width: 800px) {
  .settings-grid {
    gap: 10px;
    padding: 10px;
  }

  .settings-container {
    margin-bottom: 0;
  }
}

