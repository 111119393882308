
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: content-box;
}

.dashboard-div-container {
  background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG2.png');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;

  /*background-repeat: no-repeat;
  background-size: 2350px;
  background-position: center top -190px;*/
}

.dash-settings-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-right: 20px;
  margin-left: 20px;
}

.white-border{
  border: 2px solid white;
  border-radius: 10px;
}


.app-header-container{
  padding: 0;
  margin: 0;
}

.app-header-buttons-right{
  margin: 0;
  padding: 0;
}

.app-header-buttons-left{
  margin: 0;
  padding: 0;
}

.logo-div {
  padding: 0px;
  display: flex;
  margin-top: 10px;
}

.logo-div img {
  padding: 0px;
  margin-top: 10px;
  height: auto; /* Adjust the height of the logo as needed */
}

.dash-logo {
  position: absolute; /* Position the logo absolutely */
  left: 50%; /* Center it horizontally */
  top: 0px;
  transform: translateX(-50%); /* Adjust for the exact center */
  max-width: 400px; /* Match the max-width of the signup container */
  width: 100%; /* Take the full available width, up to the max-width */
}

.dash-logo-app-header {
  max-width: 400px; /* Match the max-width of the signup container */
  width: 100%; /* Take the full available width, up to the max-width */
}

.buttons-div {
  padding: 2px;
  display: flex;
  align-items: right;

  margin-right: 35px;
  gap: 10px;
}

.buttons-div button {
  margin-top: 10px;
  padding: 10px 20px; /* Add padding to give the button some space */
  border: none; /* Remove the default border */
  border-radius: 5px; /* Add rounded corners */
  background-color: #007bff;
  color: white; /* Set the text color */
  font-size: 16px; /* Set the font size */
  cursor: pointer; /* Change the cursor to a pointer on hover */
  transition: background-color 0.3s ease;
}

.buttons-div button:hover {
  background-color: #0056b3;
}


.settings-div {
  display: flex;
  box-shadow: inset 0px 0px 10px black;
}

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  max-height: 100vh;
  height: calc(100vh - 90px); /* Considering settings container height */
  overflow: hidden;
  gap: 25px;
  padding: 65px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}

.quarter-div {
  display: flex;
  background-color: #68f60c;
  padding: 3px; /* Space for the inset div */
  box-shadow: 0px 0px 15px black;
  box-sizing: border-box; /* Include padding in the total size */
  transition: filter 0.3s; /* smooth transition */
  border-radius: 10px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform .5s ease-out;

}

.quarter-div:hover {
    filter: brightness(2); /* Increase to a value greater than 1 to lighten the image */
}

.inset-div-common-gradient {
  background-blend-mode: overlay; /* This will blend the gradient with the image */
  background-size: cover, 40vw 40vh;
  background-position: center top, center center;
}

.inset-div1 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
}

.inset-div2 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
}

.inset-div3 {
  position: relative;
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
}

.inset-div4 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
}

.inset-div5 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
}

.inset-div6 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 30vw, 30vh;
  background-position: center 90%;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
  border-radius: 10px;
  position: relative;
}

.coming-soon-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-weight: bold;
  background-color: black;
}

.inset-div6:hover .coming-soon-message {
  display: block;
}

.btnlabel {
  display: inline-block;
  color: white;
    left: 50%;      /* Center the label horizontally */
    transform: translateX(-50%);
  text-align: center;
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  z-index: 10;
  white-space: nowrap;

}

.screen-btn {
  overflow: hidden;
}

.user-name-dash {
    display: inline;
    margin-top: 20px;
    padding-top: 10px;
    position: relative;
    top: 0%;   /* Distance from the bottom of the button */
    left: 45%;   /*   Center the label horizontally */
    color: white;
    font-size: 18pt;
    box-sizing: content-box;
    font-weight: bolder;
}

.buttons-div {
    position: relative; /* This ensures the dropdown is positioned relative to this container */
}

.dropdown {
    position: absolute; /* This makes the dropdown appear right below the button */
    top: 100%;          /* This positions it right below the button, since the button's height is 100% of its parent */
    left: 0;
    z-index: 1;         /* Ensures dropdown appears on top of other elements */
    border: 1px solid white;  /* White outline */
    background-color: black;  /* Black background */
    width: 200px;            /* Adjust as needed */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Adds a shadow for a floating effect */
}

.dropdown div {
    color: white;           /* White text color */
    padding: 8px 16px;      /* Padding for each store item */
    cursor: pointer;        /* Indicates the item is clickable */
}

.dropdown div:hover {
    background-color: #555; /* Darkens the background on hover */
}

.download-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px; /* Adjust the size as needed */
  color: white; /* Adjust the color as needed */
}

.inset-div {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.5s ease-out;
  height: 100%;  /* Adjust as necessary */
  width: 100%;   /* Adjust as necessary */
  overflow: hidden;
  position: relative;
}
.inset-div .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-out;
}

.topleftbuttons {
  margin-left: 35px;
}

.button-pulse {
  position: relative; /* Changed to relative */
  display: inline-block; /* To contain the pulse effect within */
  height: 100%;
  overflow: hidden;
  background-color: #212121; /* Button background color */
  border: none; /* Remove border if not needed */
  cursor: pointer;
}

.button-pulse:after {
  content: "";
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100"><polyline fill="none" stroke-width="3" stroke="white" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>') no-repeat center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse 2s infinite linear;
}




@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  10% {
    clip: rect(0, 66.6666666667px, 100px, 0);
  }
  38% {
    clip: rect(0, 133.3333333333px, 100px, 0);
  }
  48% {
    clip: rect(0, 200px, 100px, 0);
  }
  52% {
    clip: rect(0, 200px, 100px, 0);
  }
  62% {
    clip: rect(0, 200px, 100px, 66.6666666667px);
  }
  90% {
    clip: rect(0, 200px, 100px, 133.3333333333px);
  }
  100% {
    clip: rect(0, 200px, 100px, 200px);
  }
}

@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  10% {
    clip: rect(0, 66.6666666667px, 100px, 0);
  }
  38% {
    clip: rect(0, 133.3333333333px, 100px, 0);
  }
  48% {
    clip: rect(0, 200px, 100px, 0);
  }
  52% {
    clip: rect(0, 200px, 100px, 0);
  }
  62% {
    clip: rect(0, 200px, 100px, 66.6666666667px);
  }
  90% {
    clip: rect(0, 200px, 100px, 133.3333333333px);
  }
  100% {
    clip: rect(0, 200px, 100px, 200px);
  }
}

.healthcheck {
  opacity: 0;
  transition: opacity 0.5s ease; /* This will ensure the text fades in */
  color: red;
  font-weight: bold;
}

/* This will change the opacity to 1 (fully visible) when the user hovers over the .healthcheck element */
.button-pulse:hover .healthcheck {
  opacity: 1;
}


/* Media query for small screens */
@media screen and (max-width: 1200px) {
  .dash-settings-container {
    flex-direction: row;
    margin-bottom: 10px; /* Add margin between buttons on small screens */
  }

  .buttons-div {
    margin-right: 35px;
  }

  .dashboard-container {
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .logo-div img {
    max-width: 200px;
  }

  .btnlabel {
    font-size: 15pt;

  }
  .inset-div1 {
      background-repeat: no-repeat;
      background-color: black;
      background-size: 50vw, 50vh;
      background-position: center center;
      flex-grow: 1;
      padding: 10px;
      box-shadow: inset 0px 0px 10px black;
      color: white;
      font-size: 20pt;
      display: inline-block;
  }

  .inset-div2 {
      background-repeat: no-repeat;
      background-color: black;
      background-size: 50vw, 50vh;
      background-position: center center;
      flex-grow: 1;
      padding: 10px;
      box-shadow: inset 0px 0px 10px black;
      color: white;
      font-size: 20pt;
      display: inline-block;
  }
  .inset-div3 {
      background-repeat: no-repeat;
      background-color: black;
      background-size: 50vw, 50vh;
      background-position: center center;
      flex-grow: 1;
      padding: 10px;
      box-shadow: inset 0px 0px 10px black;
      color: white;
      font-size: 20pt;
      display: inline-block;
  }
  .inset-div4 {
    background-repeat: no-repeat;
    background-color: black;
    background-size: 50vw, 50vh;
    background-position: center center;
    flex-grow: 1;
    padding: 10px;
    box-shadow: inset 0px 0px 10px black;
    color: white;
    font-size: 20pt;
    display: inline-block;
}
.inset-div5 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 50vw, 50vh;
  background-position: center center;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
}
.inset-div6 {
  background-repeat: no-repeat;
  background-color: black;
  background-size: 50vw, 50vh;
  background-position: center center;
  flex-grow: 1;
  padding: 10px;
  box-shadow: inset 0px 0px 10px black;
  color: white;
  font-size: 20pt;
  display: inline-block;
}
  .user-name-dash {
    display: none;
  }
  .dash-logo {
    top:0px;
  }
}
@media screen and (max-width: 800px) {
  .btnlabel {
    font-size: 12pt;

  }

  .buttons-div {
    margin: 0;
  }


  .dashboard-container {
    padding-top: 0px;
    padding-bottom: 120px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .inset-div1 {
      background-size: 70vw, 70vh;
  }
  .inset-div2 {
      background-size: 70vw, 70vh;
  }
  .inset-div3 {
      background-size: 70vw, 70vh;
  }
  .inset-div4 {
      background-size: 70vw, 70vh;
  }
  .inset-div5 {
    background-size: 70vw, 70vh;  
  }
  .inset-div6 {
      background-size: 70vw, 70vh;
  }


  .buttons-div button {
    margin-top: 10px;
    padding: 10px 20px; /* Add padding to give the button some space */
    border: none; /* Remove the default border */
    border-radius: 5px; /* Add rounded corners */
    background-color: #007bff;
    color: white; /* Set the text color */
    font-size: 10px; /* Set the font size */
    cursor: pointer; /* Change the cursor to a pointer on hover */
    transition: background-color 0.3s ease;
  }


}




















/*1080x1920 VERTICAL TV OR DISPLAY*/

@media only screen and (min-height: 1500px) {
  .inset-div .background-image {
    object-fit: contain;
    display: none;
  }
}



.store-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.star-icon {
  margin-right: 10px;
  color: #ccc; /* Empty star color */
  cursor: pointer;
  width: 16px;
}

.star-icon.filled {
  color: #ffcc00; /* Filled star color */
}

