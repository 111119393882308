.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.conf-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    text-align: center;
}

.conf-h2 {
    margin-bottom: 20px;
}

.conf-p {
    margin-bottom: 30px; /* Added space between the text and the buttons */
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
}

.conf-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Added transition for color and box-shadow */
    flex: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Added shadow to buttons */
}

.conf-button:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Darker shadow on hover */
}

.confirm-button {
    background-color: #D15091;
    color: black;
    margin-right: 10px;
}

.confirm-button:hover {
    background-color: #b23b68; /* Darker red on hover */
}

.cancel-button {
    background-color: #68f60c;
    color: black;
    margin-left: 10px;
}

.cancel-button:hover {
    background-color: #45a049; /* A shade darker green on hover */
}
