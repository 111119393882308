.scrolling-menu-container {
    height: 100vh;
    overflow: hidden;
    background-color: #f5f5f5;
    padding: 0 5px;
    position: relative;
}

.scrolling-menu-container::before {
    display: none;
}

.scrolling-menu-header {
    padding: 15px 15px 20px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;
    z-index: 2;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);
}

.scrolling-menu-header.hidden {
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
}

.scrolling-menu-header.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}

.scrolling-menu-title {
    text-align: center;
    color: #333;
    margin: 0;
}

.scrolling-menu-ticker-wrapper {
    position: relative;
    z-index: 2;
    height: 100vh;
    overflow: hidden;
    transition: height 0.3s ease;
    transform: translateZ(0);
}

.scrolling-menu-ticker {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    animation: scrollVertical 120s linear infinite;
    will-change: transform;
    transform-origin: top center;
    transform: translateZ(0);
}

.scrolling-menu-product-card {
    width: 600px;
    height: 120px;
    margin: 0 auto;
    flex: 0 0 auto;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    overflow: hidden;
    transition: transform 0.3s ease;
    opacity: 0.95;
    display: flex;
}

.scrolling-menu-product-card:hover {
    transform: scale(1.02);
    opacity: 1;
}

.scrolling-menu-product-image {
    width: 120px;
    height: 120px;
    overflow: hidden;
    background-color: #f0f0f0;
    flex-shrink: 0;
}

.scrolling-menu-product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
}

.scrolling-menu-product-info {
    flex-grow: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.scrolling-menu-product-header {
    margin-bottom: auto;
}

.scrolling-menu-product-name {
    margin: 0;
    font-size: 1.2em;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 10px;
}

.scrolling-menu-product-brand {
    font-style: normal;
}

.scrolling-menu-product-details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
    padding-right: 15px;
}

.scrolling-menu-product-metadata {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    width: auto;
    margin-left: auto;
}

.scrolling-menu-product-brand,
.scrolling-menu-product-category,
.scrolling-menu-product-strain {
    color: #333;
    font-size: 1.2em;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: visible;
    max-width: none;
    flex: 0 0 auto;
}

.scrolling-menu-product-category {
    background-color: #f0f0f0;
}

.scrolling-menu-product-strain {
    background-color: #e8f5e9;
}

.scrolling-menu-product-price-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    padding: 10px 20px;
    margin-left: auto;
    background-color: rgba(245, 245, 245, 0.7);
    border-radius: 8px;
    border-left: 3px solid #b7b7b7;
    height: 100%;
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
}

.scrolling-menu-product-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
}

.scrolling-menu-product-price-label {
    font-size: 0.8em;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

.scrolling-menu-product-price {
    font-weight: bold;
    color: #4CAF50;
    font-size: 2.4em;
    white-space: nowrap;
    line-height: 1;
}

/* Zoomed card adjustments */
.scrolling-menu-product-card.zoomed .scrolling-menu-product-price {
    font-size: 1.8em;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-name {
    font-size: 1.6em;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-brand {
    font-size: 1.1em;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-category,
.scrolling-menu-product-card.zoomed .scrolling-menu-product-strain {
    font-size: 1em;
    padding: 6px 12px;
}

/* Double layout adjustments */
.scrolling-menu-product-card.double {
    width: 1000px;
    height: 120px;
    margin: 0;
    padding-right: 10px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-image {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    object-fit: contain;
}

.scrolling-menu-product-card.double .scrolling-menu-product-image img {
    object-fit: contain;
}

.scrolling-menu-product-card.double .scrolling-menu-product-info {
    padding: 12px 25px;
    flex: 1;
}

.scrolling-menu-product-card.double .scrolling-menu-product-header {
    gap: 4px;
    margin-bottom: 4px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-name {
    font-size: 1.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 1.2;
    padding-right: 10px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-brand {
    font-size: 0.9em;
}

.scrolling-menu-product-card.double .scrolling-menu-product-details {
    gap: 12px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-metadata {
    gap: 8px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-category,
.scrolling-menu-product-card.double .scrolling-menu-product-strain {
    font-size: 0.9em;
    padding: 4px 8px;
    max-width: 140px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-price-container {
    gap: 1px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-price-label {
    font-size: 0.8em;
}

.scrolling-menu-product-card.double .scrolling-menu-product-price {
    font-size: 2em;
}

/* Adjust animation for different layouts */
@keyframes scrollVertical {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -50%, 0);
    }
}

.scrolling-menu-loading {
    text-align: center;
    padding: 50px;
    font-size: 1.2em;
    color: #666;
}

.scrolling-menu-error {
    text-align: center;
    padding: 50px;
    color: #ff4444;
    font-size: 1.2em;
}

.scrolling-menu-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
}

.layout-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;
}

.layout-button:hover {
    background-color: #e0e0e0;
}

.layout-button.active {
    background-color: #4CAF50;
    color: white;
}

.scrolling-menu-double-wrapper {
    display: flex;
    gap: 8px;
    padding: 0 8px;
    height: calc(100vh - 120px);
}

.scrolling-menu-ticker-wrapper.left,
.scrolling-menu-ticker-wrapper.right {
    flex: 1;
    overflow: hidden;
}

.scrolling-menu-ticker.offset {
    animation: scrollVertical 120s linear infinite;
}

/* Product card variations */
.scrolling-menu-product-card.single {
    width: 900px;
    height: 120px;
}

.scrolling-menu-product-card.double {
    width: 100%;
    height: 100px;
    margin: 0 auto;
}

.scrolling-menu-product-card.zoomed {
    width: 98vw;
    height: 220px;
    max-width: 1600px;
    margin: 0 auto;
    padding: 20px;
    gap: 25px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-image {
    width: 220px;
    height: 220px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-info {
    padding: 20px 40px;
    justify-content: space-between;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-header {
    gap: 12px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-name {
    font-size: 2.4em;
    max-width: calc(100% - 40px);
    line-height: 1.2;
    font-weight: 600;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-brand {
    font-size: 1.6em;
    color: #444;
    font-weight: 500;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-details {
    gap: 30px;
    align-items: center;
    margin-top: 15px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-metadata {
    display: flex;
    gap: 20px;
    align-items: center;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-category,
.scrolling-menu-product-card.zoomed .scrolling-menu-product-strain {
    font-size: 1.4em;
    padding: 10px 20px;
    max-width: none;
    background-color: rgba(245, 245, 245, 0.9);
    border-radius: 6px;
    font-weight: 500;
    color: #444;
    letter-spacing: 0.5px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-strain {
    background-color: rgba(232, 245, 233, 0.9);
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price-section {
    min-width: 500px;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    width: 100%;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price-label {
    font-size: 3.75em;
    letter-spacing: 3px;
    margin-bottom: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price {
    font-size: 10.5em;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 0.9;
    width: 100%;
    text-align: center;
}

/* Add these styles for the filter controls */
.scrolling-menu-controls-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.scrolling-menu-filter-controls {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 8px;
    border-radius: 4px;
}

.filter-select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 0.9em;
    cursor: pointer;
    min-width: 150px;
    transition: all 0.3s ease;
}

.filter-select:hover {
    border-color: #4CAF50;
}

.filter-select:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(74, 175, 80, 0.2);
}

/* Single view adjustments */
.scrolling-menu-product-card.single .scrolling-menu-product-price-section {
    min-width: 100px;
}

.scrolling-menu-product-card.single .scrolling-menu-product-price {
    font-size: 3em;
}

/* Double view adjustments */
.scrolling-menu-product-card.double .scrolling-menu-product-price-section {
    min-width: 100px;
    padding: 8px 15px;
}

.scrolling-menu-product-card.double .scrolling-menu-product-price {
    font-size: 2.4em;
}

/* Zoomed view adjustments */
.scrolling-menu-product-card.zoomed .scrolling-menu-product-price-section {
    min-width: 220px;
    padding: 15px 30px;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price-label {
    font-size: 2em;
}

.scrolling-menu-product-card.zoomed .scrolling-menu-product-price {
    font-size: 4em;
}

/* Update product info layout */
.scrolling-menu-product-info {
    flex-grow: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Ensure product details container properly spaces elements */
.scrolling-menu-product-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-top: auto;
    padding-right: 15px;
}

/* Update metadata section */
.scrolling-menu-product-metadata {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: auto;
}

.scrolling-menu-product-category,
.scrolling-menu-product-strain,
.scrolling-menu-product-brand {
    color: #333;
    font-size: 1.2em;
    font-weight: 600;
    padding: 4px 12px;
    border-radius: 4px;
    white-space: nowrap;
    overflow: visible;
    max-width: none;
    flex-shrink: 1;
}

.scrolling-menu-product-brand {
    background-color: #e3e3e3;
    font-style: normal;
}

.scrolling-menu-product-category {
    background-color: #f0f0f0;
}

.scrolling-menu-product-strain {
    background-color: #e8f5e9;
}

/* Adjust spacing in product info section */
.scrolling-menu-product-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-top: auto;
    padding-right: 15px;
}

/* Zoomed view adjustments */
.scrolling-menu-product-card.zoomed .scrolling-menu-product-category,
.scrolling-menu-product-card.zoomed .scrolling-menu-product-strain,
.scrolling-menu-product-card.zoomed .scrolling-menu-product-brand {
    font-size: 1.8em;
    padding: 8px 16px;
    max-width: none;
}

/* Double view adjustments */
.scrolling-menu-product-card.double .scrolling-menu-product-category,
.scrolling-menu-product-card.double .scrolling-menu-product-strain,
.scrolling-menu-product-card.double .scrolling-menu-product-brand {
    font-size: 1.4em;
    padding: 6px 14px;
    max-width: none;
}

/* Add sync controls styling */
.scrolling-menu-sync-controls {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 5px;
}

.sync-button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9em;
}

.sync-button:hover {
    background-color: #e0e0e0;
}

.sync-button.active {
    background-color: #4CAF50;
    color: white;
}

/* Update ticker animations for different sync modes */
.scrolling-menu-ticker.sync {
    animation: scrollVertical 120s linear infinite;
}

.scrolling-menu-ticker.offset {
    animation: scrollVertical 120s linear infinite;
}

.scrolling-menu-ticker.offset.right {
    animation-delay: -60s;
}

.scrolling-menu-ticker.speed {
    animation: scrollVertical 120s linear infinite;
}

.scrolling-menu-ticker.speed.right {
    animation-duration: 90s;
}

/* Ensure vertical scrolling for both columns */
.scrolling-menu-ticker.right,
.scrolling-menu-ticker.left {
    display: flex;
    flex-direction: column;
    animation: scrollVertical 120s linear infinite;
    gap: 10px;
    padding: 12px;
} 