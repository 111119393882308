@import '@fortawesome/fontawesome-free/css/all.min.css';

.cfd-button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 100px;
}

.cfd-button-container button {
    width: 180px;
    height: 80px; /* Adjusted height to be similar to products */
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    cursor: pointer;
    background-color: #f7f7f7;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: bold;
}

.cfd-button-container button:hover {
    background-color: #ddd;
    transform: scale(1.05);
}


.product-row {
    overflow-y: hidden;
    display: flex;
    overflow-x: auto; 
    gap: 20px;
    align-items: flex-start;
    position:relative;
    scrollbar-width: none;
}

.product-row:last-child {
    padding-bottom: 50px; /* Adjust the value as needed */
    box-sizing: border-box;
}

.product-row::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Optional: just make scrollbar invisible */
  }

.products {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    height: 95vh;
    overflow-y: auto; 
}

.brand-label {
    font-size: 2em;
    font-weight: bolder;
    margin-bottom: 10px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; /* white outline */
    cursor: pointer; /* Indicates the item is clickable */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the glow effect */
}

.brand-label:hover {
    box-shadow: 0 0 8px 2px red; /* Glowing effect */
}

.product img, .placeholder-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.product:hover {
    transform: scale(1.05);
}

.placeholder-image {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999;
}

.product {
    position: relative;
    width: 200px;             /* Fixed width for each product */
    flex: 0 0 auto;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    display: flex;            /* Flex properties to center content */
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
}

.product img, .placeholder-image {
    width: 150px;            /* Fixed width for images */
    height: 150px;           /* Fixed height for images */
    object-fit: cover;
    margin-bottom: 10px;     /* Spacing between image and product name */
    border-radius: 10px;
}

.product h3 {
    font-size: 1em;
    margin-bottom: 5px;  /* Spacing between product name and price */
    text-align: center;  /* Center the title text */
}

.product p:not(.validity-period) {
    font-size: 1.2em;
    text-align: center;  /* Center the price text */
}

.product:hover {
    transform: scale(1.05);
}

.placeholder-image {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999;
    border-radius: 10px;
}

.discount-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% + 20px); /* Adjust according to your padding */
    margin: 0 -10px; /* Adjust according to your padding */
}


.sale-banner {
    background-color: red;
    color: white;
    text-align: center;
    padding: 5px 0;
    font-size: 1.2em;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
}

.discount-name {
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    color: white;
    text-align: center;
    padding: 2px 0;
    font-size: 0.7em;
    white-space: pre-line;
}

.product:hover .discount-name {
    display: block;
}

.sort-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.SortButton {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    color:white;
    background-color: black;
    margin-top: 20px;
    border-radius: 10px;
}

.arrow {
    position: sticky;
    top: 50%;
    font-size: 2em;
    color: white;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .left-arrow {
    left: 0;
  }
  
  .right-arrow {
    right: 0;
  }
  
  .product-row:hover .arrow {
    opacity: 1;
  }

  .original-price {
    text-decoration: line-through;
    font-size: 1.8em;
    color: #888;
  }
  
  .discounted-price {
    color: red;
    font-size: 2em;
    font-weight: bold;
  }

  .validity-period {
    font-size: 0.6em;
    color: black;
  }

  .master-category-container {
    margin-bottom: 20px;
    border: 5px solid #eee;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
}

.master-category-title {
    background-color: #f0f0f0;
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above everything else */
}

.loading-message {
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
