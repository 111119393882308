.sync-dropdown select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sync-dates input[type="date"] {
    width: 48%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sync-dates {
    display: flex;
    justify-content: space-between;
}

.sync-modal-smart-schedule {
    margin-top: 20px;
  }
  
  .sync-modal-smart-schedule label {
    display: block;
    margin-bottom: 10px;
  }
  
  .sync-modal-smart-schedule div {
    margin-bottom: 10px;
  }
  
  .sync-modal-smart-schedule input[type="number"],
  .sync-modal-smart-schedule input[type="time"] {
    padding: 5px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  