.reorder-report {
    padding: 20px;
    overflow-y: auto;
    z-index: 1;
  }
  
  .title {
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .loading {
    text-align: center;
    margin-top: 50px;
  }

  .reorder-wrapper {

    overflow-y: auto;
  }

  /* HTML: <div class="loader"></div> */
.loader-div {
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 #fff;
  position: relative;
  display: grid;
  clip-path: inset(-60px 0 -5px)
}
.loader-div:after {
  content: "";
  position: relative;
  background: repeating-linear-gradient(90deg,#0000 0 calc(50% - 8px), #ccc 0 calc(50% + 8px), #0000 0 100%) 0 0/calc(100%/3) 100%;
  animation: l6-1 1s infinite;
} 
.loader-div:before {
  content: "";
  position: absolute;
  width: 14px;
  aspect-ratio: 1;
  left: calc(50% - 7px);
  bottom: 0;
  border-radius: 50%;
  background: lightblue;
  animation: l6-2 1s infinite;
}
@keyframes l6-1 {
  50%,100% {background-position: calc(100%/2) 0}
}
@keyframes l6-2 {
  0%,50% {transform:translateY(-80px)}
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}

.loader-div {
  /* Your existing styles */
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 #fff;
  position: relative;
  display: grid;
  clip-path: inset(-60px 0 -5px);
  /* More styles... */
}


.date-picker-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0; /* Adjust as needed */
  
  gap: 5px;
  padding: 10px;
  padding-bottom: 0;
}

.date-picker-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date-label {
  margin-bottom: 5px;
  color: white; /* Set label color to white */
  font-weight: bold; /* Optional: to make labels stand out */
}

.total-cost, .remaining-cost {
  color: white;
  font-weight: bold;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #fff;
}

.supplychain-toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-text {
  margin-right: 2px;
  font-size: 14px; /* Adjust font size as needed */
}

.toggle-checkbox {
  display: none;
}

.reorder-slider {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.reorder-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .reorder-slider {
  background-color: #68f60c;
}

input:checked + .reorder-slider:before {
  transform: translateX(11px);
}


@media screen and (max-width: 800px) {
  .date-picker-container {
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    display: grid;
    padding: 0;
  }

  .sorting-buttons {
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    display: grid;
  }

  .title {
    margin: 0;
  }

  .reorder-report{
    padding: 5px;
  }

  .date-picker-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}