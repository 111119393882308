.signup-container {
  width: 50%;
  height: auto;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  /* margin-top: 10vh; */
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
}

.page-container {
  background-image: url('https://d27oebqltxtb0s.cloudfront.net/LoginBG.png');
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  background-size: 125vw; /* Cover the entire container */
  background-position: 35% 35%; /* Center the image */
  display: flex; /* To center the signup-container */
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
}

  .div-container {
    background: linear-gradient(to bottom, black 40%, #a6a9a5 40%);
    background-image: none;
  }

.logo {
  position: absolute; /* Position the logo absolutely */
  top: 10vh; /* Align it with the top of the page-container */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust for the exact center */
  max-width: 400px; /* Match the max-width of the signup container */
  width: 100%; /* Take the full available width, up to the max-width */
}

.login-link-container {
  padding: 5px;
  color: white;
}

.signup-title {
  font-size: 36px;
  margin-bottom: 5px;
  color: white;
}

.signup-subtitle {
  font-size: 16px;
  margin-bottom: 10px;
  color: grey;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.name-container,
.email-password-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.name-input,
.company-input {
  width: 48%;
}

.email-password-container input {
  width: 100%;
}

.submit-button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.name-input {
  padding:5px;
  border-radius: 10px;
}
.submit-button { 
  border-radius: 10px;
  margin: 0px;
}
.email-password-container {
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}


.forminput {
  padding: 5px;
  border-radius: 10px;
  margin: 2px;
}

.createanaccount {
  color: white;
}

.login-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust gap as needed */
  justify-content: center; /* Center the buttons horizontally */
}

.extra-button {
  flex: 1 1 auto; /* Allow buttons to grow and shrink */
  max-width: 200px; /* Set a max width for the buttons */
  margin: 5px; /* Adjust margin as needed */
}





@media only screen and (max-width: 1500px) { /* Adjust the value as needed */
  .signup-container {
    margin-top: 30vh; /* Increase the space on smaller screens */
  }
  .signup-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: white;
  }
  .login-link-container {
    font-size: 12px;
    padding: 5px;
    color: white;
  }
  .logo {
    top: 20px; /* Align it with the top of the page-container */
    max-width: 200px;
  }
  .page-container {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/LoginBG.png');
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full viewport width */
    background-size: 150vw; /* Cover the entire container */
    background-position: -20vw -30vh;
    background-color: #a6a9a5;
    display: flex; /* To center the signup-container */
    flex-direction: column;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative;
    background-repeat: no-repeat;
  }
  .div-container {
    background: linear-gradient(to bottom, black 40%, #a6a9a5 40%);
  }

}








@media only screen and (max-width: 750px) { /* Adjust the value as needed */
  .signup-container {
    margin-top: 40vw; /* Increase the space on smaller screens */
    width: 80vw;
  }
  .signup-title {
    font-size: 20px;
    margin-bottom: 5px;
    color: white;
  }
  .login-link-container {
    font-size: 12px;
    padding: 5px;
    color: white;
  }

  .signup-subtitle {
    font-size: 10px;
    margin-bottom: 10px;
    color: grey;
  }

  .page-container {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/LoginBG.png');
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full viewport width */
    background-size: 125vw; /* Cover the entire container */
    background-position: center top;
    display: flex; /* To center the signup-container */
    flex-direction: column;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative;
    background-repeat: no-repeat;
  }
  .div-container {
    background: linear-gradient(to bottom, black 35%, #a6a9a5 35%);
  }

  .name-container {
    flex-direction: column;
  }
  .name-input {
    padding:5px;
    border-radius: 10px;
    width: auto;
  }
  .submit-button { 
    border-radius: 10px;
    margin: 0px;
  }
  .email-password-container {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
  }
}


