.terms-wrapper {
    max-height: 80vh; /* Adjust the height as needed */
    overflow-y: auto;
    padding: 20px;
}

.terms-content {
    text-align: justify;
    color: white;
    background-color: rgba(0,0,0,0.7);
    border-radius: 10px;
}

.tncwrapper {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}

.terms-wrapper h1, .terms-wrapper h2 {
    text-align: center;
    color: white;
}

.tnc-header {
    color: white;
}
