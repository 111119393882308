.minmax-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .minmax-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
  
  .minmax-modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .minmax-modal-content label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .minmax-modal-content input {
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
  }
  
  .minmax-modal-content button {
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  