.container {
  position: relative;
  width: 100%; /* Fill the width of the parent */
  aspect-ratio: 4 / 3; /* Maintain a 4:3 aspect ratio */
  border-radius: 20px;
  overflow: hidden;
}

.slideimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 20px;
}

.slider {
  position: absolute;
  appearance: none;
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 0.3);
  outline: none;
  margin: 0;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  z-index: 1; /* Make sure the slider appears above other elements */
}

.slider:hover {
  background: rgba(242, 242, 242, 0.1);
}

.slider::-webkit-slider-thumb,
.slider::-moz-range-thumb {
  appearance: none;
  width: 6px;
  height: 100%; /* Full height of the container */
  background: white;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.sliderButton {
  position: absolute;
  width: 30px; /* Fixed size for button */
  height: 30px;
  border-radius: 50%;
  background-color: white;
  left: calc(var(--slider-pos, 50%) - 15px); /* Center the button */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2; /* Ensure it appears above images */
}

.sliderButton::after,
.sliderButton::before {
  content: '';
  padding: 3px;
  display: inline-block;
  border: solid #5d5d5d;
  border-width: 0 2px 2px 0;
}

.sliderButton::after {
  transform: rotate(-45deg);
}

.sliderButton::before {
  transform: rotate(135deg);
}
