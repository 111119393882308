.ai-insights-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ai-insights-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #ddd; /* White border */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); /* Box shadow for modal */
    width: 60%;
    max-width: 600px;
  }
  
  .close-button {
    float: right;
    background: none;
    border: none;
    font-size: 1.2em;
  }
  
  .prompt-input {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .response-area {
    margin-top: 20px;
    border: 1px solid #eee;
    padding: 15px;
    min-height: 100px; /* Ample space for the answer */
    background-color: #f9f9f9;
    overflow-y: auto; /* In case of overflow */
  }
  