.individual-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.individual-modal-content {
    background-color: black;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    height: 95%;
    color: rgb(255, 255, 255);
    font-family: 'Courier New', Courier, monospace;
    overflow: auto;
    position: relative;
}

.individual-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: red;
    font-size: 20px;
    cursor: pointer;
}

/* Title styling - same color as SKU name and smaller */
.individual-modal-terminal-content h3 {
    font-size: 1.3em;
    color: #0f0;
    margin-bottom: 15px;
    text-align: center;
}

/* Horizontally align deal-wide information */
.deal-info-horizontal {
    display: flex;
    justify-content: center; /* Center the items horizontally */
    text-align: center; /* Center the text within the items */
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allows items to wrap if needed */
}

.deal-info-horizontal p {
    margin: 0 10px;
    flex: 1;
    text-align: center; /* Center the text within each container */
}

/* SKU List styling */
.sku-list-container {
    max-height: 450px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid green;
    border-radius: 5px;
    background-color: #111;
}

/* SKU item styling */
.sku-item {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #222;
    border-radius: 5px;
}

/* Container for the SKU name and the progress bar */
.sku-name-and-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

/* Adjust SKU name to take up more space, leaving 1/3 for the progress bar */
.sku-name {
    flex: 0.5;
    color: #0f0; /* Same color as the title */
    margin-right: 10px;
}

/* Red outlined progress bar */
.progress-container {
    flex: 0.5; /* Progress bar takes up 1/3 of the space */
    height: 15px;
    background-color: transparent;
    border: 1px solid #0f0;
    border-radius: 5px;
    position: relative;
    cursor: pointer; /* Show pointer on hover for tooltip */
}

/* Filled red progress bar */
.progress-bar {
    height: 100%;
    background-color: #0f0;
    border-radius: 5px;
    transition: width 0.3s ease;  /* Smooth transition for dynamic changes */
}

/* Horizontally align SKU data */
.sku-details {
    display: flex;
    justify-content: center; /* Center the data horizontally within each SKU */
    text-align: center;
    flex-wrap: wrap; /* Allows text to wrap in smaller containers */
}

.sku-details p {
    margin: 0 10px;
    flex: 1;
    text-align: center; /* Center the text within each container */
}

/* Emoji breakdown section styling */
.emoji-breakdown {
    display: flex;
    justify-content: center;
    text-align: center; /* Center text horizontally */
    color: white; /* Make the text white */
    margin-bottom: 20px; /* Add a bit of margin for spacing */
}

.emoji-breakdown ul {
    list-style: none; /* Remove bullet points */
    padding: 0;
    margin: 0; /* Ensure there's no extra margin */
}

.emoji-breakdown li {
    margin: 5px 0; /* Adjust spacing between the emoji messages */
    font-size: 0.9em; /* Reduce font size */
    line-height: 1.2; /* Add some spacing for better readability */
    text-align: center; /* Ensure individual items are centered */
}

/* View toggle button styles */
.view-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.view-toggle button {
    background-color: #004d00; /* Darker green for inactive */
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
}

.view-toggle button.active {
    background-color: #00cc00; /* Brighter green for active */
}

.view-toggle button:hover {
    background-color: #006600;
}

/* Toggle label styling */
.toggle-label {
    cursor: pointer;
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Active state with brighter green */
.toggle-label.active {
    background-color: #00cc00; /* Brighter green */
    color: white;
}

/* Inactive state with darker green */
.toggle-label.inactive {
    background-color: #004d00; /* Darker green */
    color: white;
}

/* Hover effect for better interactivity */
.toggle-label:hover {
    background-color: #006600; /* Slightly lighter green for hover effect */
}

