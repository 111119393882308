.slide-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px;
}

.slide-item-left,
.slide-item-right {
    display: flex;
    align-items: center;
}

.slide-item-left span {
    font-size: 24px;
    margin-right: 10px;
}

.slide-item img, 
.slide-item video {
    margin-right: 10px;
}

.image-label {
    cursor: pointer;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.image-label:hover {
    background-color: #d0d0d0;
}

.image-label input[type="file"] {
    display: none;
}

.duration-input {
    width: 60px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
}

.remove-button {
    background-color: #D15091;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    border: none;
    margin-left: 10px;
}

.remove-button:hover {
    opacity: 0.8;
}

.input-group input {
    padding: 5px;
    border-radius: 5px;
}

.single-digit input {
    max-width: 50px;
}
