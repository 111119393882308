/* Background overlay */
.wizard-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 100px;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 1000; /* Ensure it sits above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wizard-modal-content {
    background-color: #fff;
    padding: 20px;
    margin: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto;
    max-width: 800px;
    min-width: 600px;
    min-height: 250px;
    z-index: 1001;
    position: relative; /* Needed for absolute positioning of children */
    overflow: hidden;
    justify-content: center;
    text-align: center;
    padding-bottom: 80px;
  }
  
/* Wizard controls - navigation buttons */
.wizard-controls {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center buttons horizontally */
    gap: 20px;
    position: absolute; /* Position controls absolutely within the parent */
    bottom: 20px; /* Distance from the bottom of the parent container */
    left: 0;
    right: 0; /* These ensure the controls are centered */
  }
  
  .wizard-controls button:not(.wizard-close-button) {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    background-color: #007bff;
    color: white;
  }
  
  .wizard-controls button:hover:not(.wizard-close-button) {
    background-color: #0056b3;
  }
  
  /* Close button styling */
  .close-button {
    background-color: #dc3545;
  }
  
  .close-button:hover {
    background-color: #c82333;
  }

  .wizard-close-button {
    background-color: red; /* Red background */
    color: white; /* White text */
    border: none;
    padding: 10px 20px;
    margin-left: 20px; /* Space it out from other buttons */
    border-radius: 5px; /* Optional: rounded corners */
    cursor: pointer;
}

/* Optional: Change the appearance on hover */
.wizard-close-button:hover {
    background-color: darkred;
}