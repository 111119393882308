.profile-manager {
    max-width: 80vw;
    margin: 2rem auto;
    padding-top: 0px;
    margin-top: 0px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px; /* Added padding at the bottom */
    max-height: 90vh;
}

.profile-manager input {
    margin-bottom: 10px;
    padding: 5px;
}

.profile-details, .qr-display, .user-manager {
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.profile-details p, .qr-display p, .user-details p {
    margin: 5px 0;
}

.profile-details {
    display: flex;
    justify-content: space-between;
}

.profile-button, .user-details button {
    padding: 12px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-weight: 600;
}

.profile-button:hover, .user-details button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.qr-display {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-manager h2 {
    padding: 10px;
}

.user-manager {
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* spacing between the user details */
}

.user-details {
    flex: 1;
    min-width: 220px; /* minimum width for each user detail box */
    border: 1px solid #e1e1e1;
    padding: 15px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.user-details h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.user-details button {
    display: inline-block;
    margin-top: 10px;
}

.pushnext {
    position:relative;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 15px;
}

.pagewrapper {
    width: 100%;
    height: 100%;
}

.background-wrapper {
    padding-bottom: 600px;
}

/* ProfileManager.css */

.plan-button {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-top: 10px; /* Adjust as needed */
    cursor: pointer;
}

.plan-button.selected {
    background-color: lime;
    color: white;
}

.plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Other styling... */
}

.stores-scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;

}

.cardForm {
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.cardForm input {
    padding: 5px;
}

.cardForm button {
    padding: 5px;
    margin: 5px
}

.disclaimer {
    color: red;
    font-size: 0.75em;
    padding: 5px;
}




.plan.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.plan.disabled .plan-button {
    background-color: #ccc;
    cursor: default;
}

@media screen and (max-width: 800px) {
    .profile-manager {
        max-width: 96vw;
        margin-top: 20px;
    }
}
