.export-deal-sheet-modal {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .export-deal-sheet-modal h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .size-multiplier-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  
  .size-multiplier-container label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .size-multiplier-container input {
    width: 70px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 100px;
    align-items: center;
    justify-content: space-between;
  }
  
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  .button-container button {
    padding: 10px 20px;
    border: none;
    background-color: #68f60c;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .button-container button:hover {
    background-color: #45a049;
  }
  
  .button-container button:active {
    background-color: #408040;
  }


  .asset-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.background-preview-wrapper {
    display: flex;
    flex-direction: row; /* This will arrange its children (the image-checkbox-wrapper divs) horizontally */
    flex-wrap: wrap; 
    align-items: center; 
    margin-top: 10px; /* Provides some space between the label and the backgrounds */
}

.background-preview-container {
    display: flex;
    flex-direction: row; /* To display horizontally */
    flex-wrap: wrap;     /* Wrap if the width exceeds the container */
    align-items: center;
    justify-content: flex-start; /* Align items to the start of the container */
    margin-top: 10px;
}

.background-preview-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-bottom: 5px;  /* Space between the image and its checkbox */
}

.image-checkbox-wrapper {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.image-checkbox {
    display: none; /* Hide the actual checkbox */
}

.image-checkbox + .checkbox-check-icon {
    display: none; /* Initially hide the check icon */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the icon */
    color: white; /* Change to your preferred color */
    font-size: 16px; /* Adjust as needed */
    z-index: 1;
}

.image-checkbox-wrapper {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;
    border-radius: 3px; /* Optional, for rounded corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional, for a subtle shadow */
    position: relative;
    margin-right: 15px;
    cursor: pointer; /* Indicates the element is clickable */
}


.checkbox-container {
    position: relative;
}

.image-checkbox:checked + .checkbox-check-icon {
    display: block;
}










.dot-spinner {
    --uib-size: 1.5rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  