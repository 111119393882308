.export-deal-sheet-modal2 {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 95vw; /* 80% of the viewport width */ /* maximum width to prevent the modal from becoming too wide */
  margin: 20px auto; /* top and bottom margin 20px, left and right auto */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.asset-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr)); /* Adjust grid to fit content */
  align-items: center;
  width: 100%; /* Ensure it takes the full width of the modal */
  margin-bottom: 10px;
  margin: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  justify-items: center;
  font-size: 12px;
}

.export-deal-sheet-modal2 h2 {
  color: #333;
  margin-bottom: 15px;
}

.asset-row input[type="text"],
.asset-row select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
}

.asset-row label.sync-checkbox-label {
  display: flex;
  align-items: center;
}

.asset-row input[type="checkbox"] {
  margin-left: 5px;
}

.asset-row button.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4d4f;
}

.export-modal2-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
}

.export-modal2-controls button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.export-modal2-controls button.add-asset-button {
  background-color: #52c41a;
  color: #fff;
}

.export-modal2-controls button.add-asset-wizard-button {
  background-color: #c41ab6;
  color: #fff;
}

.export-modal2-controls button.export-button {
  background-color: #1890ff;
  color: #fff;
}

.export-modal2-controls button.copy-button {
  background-color: #faad14;
  color: #fff;
}

.export-modal2-controls button.close-button {
  background-color: #f5222d;
  color: #fff;
}

.export-modal2-controls button:hover {
  opacity: 0.8;
}

/* Updated CSS for the custom dropdown */
.custom-dropdown {
  position: relative;
  width: 100px; /* Set a fixed width for the dropdown */
}

.custom-dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the button contents */
  width: 100%; /* Full width of the container */
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  padding: 0 10px;
}


.custom-dropdown-content div {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  padding: 5px 10px;
  width: 160px; /* Adjust width if necessary */
  background-color: #f9f9f9;
  cursor: pointer;
  text-align: center; /* Center the text */
}
.custom-dropdown-content div:hover {
  background-color: #ddd;
}

.custom-dropdown {
  position: relative;
  display: inline-block; /* Updated for inline display */
}

.custom-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px; /* Adjust if necessary */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 0; /* Align dropdown to the right of the button */
}

.show-dropdown-content {
  display: block; /* Show dropdown content */
}

.background-option {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 10px; /* Adjust margin for spacing */
}


.asset-row select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 25px 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.asset-row .custom-size-inputs input {
  width: 80px; /* Adjust the width of custom size inputs */
  text-align: center;
}


.asset-row input[type="text"], .asset-row select, .asset-row .custom-size-inputs input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.asset-row .custom-size-inputs input {
  width: 70px; /* Adjust the width of custom size inputs */
  text-align: center;
  margin-left: 5px; /* Space after label */
}

.asset-row .custom-size-inputs label {
  display: flex;
  align-items: center; /* Align label and input */
}

.asset-row .export-checkbox-label {
  margin-right: 10px; /* Space before the checkbox */
  display: flex;
  align-items: center; /* Align checkbox and label text */
}

.asset-row .export-checkbox {
  margin-right: 5px; /* Space between checkbox and label text */
  cursor: pointer; /* Cursor pointer to indicate it's clickable */
}

.scrollable-asset-rows {
  max-height: 70vh;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Take full width */
  padding: 10px; /* Optional padding */
  overflow-x: hidden;
  min-height: 50vh;
}

.zoom-input {
  max-width: 60px;
}

.asset-row input:not(.export-checkbox, .zoom-input, input[type='checkbox'], .wh) {
  width: 130px;
}

.asset-row select:not(div) {
  width: 110px;
}

.asset-row .custom-size-inputs input {
  padding: 0;

}


@media screen and (max-width: 800px) {
  .asset-row {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Adjust grid to fit content */
    gap: 20px;
  }
}