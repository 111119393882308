/* QueueSettingsPage.css */
.queue-settings-container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f4f4f4;
}

.queue-settings-container h2 {
    color: #333;
    margin-bottom: 20px;
}

.queue-settings-field {
    margin-bottom: 15px;
}

.queue-settings-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.queue-settings-field input[type="color"] {
    cursor: pointer;
}

.queue-settings-field input[type="file"] {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
}

.save-button {
    background-color: #68f60c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.save-button:hover {
    background-color: #68f60c;
}

.queue-background-settings {
    padding: 20px;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG4.png');
    background-size: 100vw;
    background-position: center top -60vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    height: 100vh;

}