.vendor-list {
    padding: 0px;
    padding-top: 5px;
  }
  
  .vendor-entry {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
  }
  
  .vendor-summary {
    padding: 10px;
    background-color: #f7f7f7;
    cursor: pointer;
    display: grid;
  
  }
  
  .vendor-summary:hover {
    background-color: #eaeaea;
  }
  
  .vendor-name {
    font-weight: bold;
  }
  
  .received-date {
    font-size: 0.9em;
    color: #666;
  }
  
  .vendor-details {
    padding: 10px;
    background-color: #fff;
  }
  
  .item-detail {
    display: grid;
    grid-template-columns: 0.3fr 3fr 1fr 1fr 1fr 1fr 1.4fr 1.3fr; /* Adjust the fr units as needed */
    gap: 10px;

    margin-bottom: 5px;
    justify-content: space-between;
    align-items: left;
    border: 3px solid black;
    border-radius: 5px;
    padding: 5px;
  }
  
  .item-detail span:not(.reorder-item-name) {
    flex: 1; /* Makes each span take equal width */
    text-align: center; /* Centers text in each span */
  }
  
  .sku-detail {
    font-size: 1em;
    color: #000000;
    border-radius: 5px;
    margin: 5px;
  }

  .realsku-detail {
    font-size: 1em;
    border-radius: 5px;
    margin: 5px;
  }

  .status-bar {
    width: 100%;
    background-color: #ddd;
    border-radius: 4px;
    margin: 8px 0;
    height: 20px;
    overflow: hidden;
  }
  
  .status-bar-fill {
    height: 100%;
    border-radius: 4px 0 0 4px; /* rounded corners on the left side */
    transition: width 0.3s ease;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box;
    color: white; /* Text color */
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .unit-status-bar {
    position: relative;
    width: 100%;
    background-color: #e0e0e0; /* Default background, less visible */
    border-radius: 4px; /* Rounded corners for the outer container */
    overflow: hidden;
    height: 20px;
  }
  
  .unit-status-bar-fill {
    background-color: green; /* Green color for remaining */
    height: 100%;
    border-radius: 4px 0 0 4px; /* Rounded corners on the left side only */
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  
  .unit-status-bar-depleted {
    background-color: red; /* Red color for depleted */
    height: 100%;
    border-radius: 0 4px 4px 0; /* Rounded corners on the right side only */
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  
  
  .unit-status-bar-label {
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 20px;
    color: white; /* White color for the label for better readability */
    font-weight: bold;
    z-index: 2;
    top: 0;
  }
  
  
  
  

  .vendor-summary {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 2.2fr 3fr 1fr 1.3fr 1fr; /* Adjust as needed */
    gap: 10px;
    align-items: center;
    justify-items: center;
  }

  /* Target the second and third column items */
.vendor-summary > *:nth-child(6n+3),
.vendor-summary > *:nth-child(6n+4),
.vendor-summary > *:nth-child(6n+5),
.vendor-summary > *:nth-child(6n+6), /* Every 2nd item in a set of 6 */
.vendor-summary > *:nth-child(6n+7) /* Every 3rd item in a set of 6 */ {
    justify-self: start; /* Change justify-self for these items */
}

  .vendor-summary > *:not([style*="grid-column: 4"]){
    justify-items: center;
  }
  
  .vendor-name {
    grid-column: 3; /* place in the first column */
    width: 100%;
    white-space: nowrap;    /* Prevents text from wrapping */
    overflow: hidden;       /* Hides any overflow */
    text-overflow: ellipsis;
  }

  .order-remaining-cost {
    grid-column: 6;
    padding: 0;
    margin: 0;
  }

  .order-total-cost {
    grid-column: 5;
    padding: 0;
    margin-left: 15px;
  }
  
  .status-bar-container {
    width: 100%;
    grid-column: 4; /* place in the second column */
  }
  
  .received-date {
    grid-column: 7; /* place in the third column */
    font-weight: bold;
  }

  .sorting-buttons {
    padding: 10px;
    padding-top: 0px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .sorting-buttons input {
    padding: 5px;
  }

  .sorting-buttons button {
    padding: 5px;
  }

  .sorting-buttons select {
    padding: 5px;
  }



  .item-status-bar {
    width: 100px; /* Adjust the width as needed */
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .item-status-bar-fill {
    height: 100%;
    background-color: green; /* Choose color based on your preference */
    border-radius: 5px;
  }


  
  .group {
    display: flex;
    gap: 10px;
    padding-right: 10px;
  }
  

  .vendor-button {
    font-size: 1em; /* Adjust to match the size of your h2 */
    font-weight: bold; /* Most browsers use bold for h2 */
    border: none; /* Removes default button border */
    background-color: transparent; /* Makes the background similar to a typical h2 */
    cursor: pointer; /* Keeps the cursor as a pointer to signify it's clickable */
    color: inherit; /* Inherits the text color from the parent element */
    text-align: left; /* Aligns text like a typical h2, adjust as needed */
    display: inline-block; /* Optional, makes it flow like text */
    justify-self: start;
    
}

.vendor-button:hover {
  color: #007bff !important; /* Changes text color on hover */
}

.reorder-item-name {
  white-space: nowrap;    /* Prevents text from wrapping */
  overflow: hidden;       /* Hides any overflow */
  text-overflow: ellipsis;
  text-align: left;
}
  

.export-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 20px;
  top: 0;
  gap:20px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.export-modal-content {
  
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  gap: 20px;
  margin: 20px; /* For smaller screens */
}

.export-modal-content > div {
  margin: 10px;
}

.export-modal-content > .button-container > button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Top/bottom and left/right padding */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor changes to pointer when hovered over */
  margin-top: 10px; /* Margin at the top */
}

.export-modal-content > .button-container > button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.button-container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 10px; /* Optional: adds space between buttons */
}


.left-align{
  text-align: left;
}



.sku-detail.received-margin {
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
}

.sku-detail.received-margin:hover {
  border: 4px solid blue;
}

/* Tooltip container */
.sku-detail.received-margin .tooltip {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
}

.sku-detail.received-margin .tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you hover over the tooltip container */
.sku-detail.received-margin:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.more-product-info {
  background-color: #007bff;
  color: white;           /* White text */
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
}

.more-product-info:hover {
  background-color: darkblue; /* Darker blue on hover */
}


@media screen and (max-width: 800px) {
  .vendor-summary {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px; /* Adjust gap as needed */
    display: block;
  }

  .group {
    flex-direction: column; /* Stack buttons and inputs vertically */
    padding-top: 10px;
  }

  /* Ensure vendor entries stack vertically without specific column configuration */
  .vendor-entry {
    display: block;
  }

  .left-align {
    padding: 2px;
    white-space: nowrap;
    text-align: center;
  }

  .vendor-name, .vendor-button {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .order-total-cost {
    margin: 0;
  }

  .item-detail input {
    min-width: 50px;
    gap: 20px;
  }


  .item-detail {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px; /* Adjust gap as needed */
    background-color: white;
  }

  .sku-detail, .realsku-detail {
    white-space: nowrap;
  }

}
