.sheet-modal-content {
    min-width: 85vw;
    max-width: 100vw;
    width: auto;
    margin:0;
    border-radius: 10px;
}

.category {
    flex: 0 0 auto;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    min-width: 60vw;
    box-sizing: border-box;
}

.category-container {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    flex: 1;
    min-width: 60vw;
    background-color: black;
    overflow: hidden;
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.category-name {
    border: 1px solid #ccc;
    padding: 5px;
    width: 200px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.2em;
}

.btn-sync, .btn-delete-category {
    padding: 5px;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.btn-sync {
    margin: 2px;
    background-color: #68f60c;
    color: black;
}

.btn-sync:hover {
    background-color: #43a047;
}

.btn-delete-category {
    background-color: #D15091;
    color: black;
}

.btn-delete-category:hover {
    background-color: #b13d7b;
}

.deals-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: black;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.categories-scrollable {
    display: inline-block;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden; /* No horizontal scrolling */
    padding-right: 15px;
    width: 100%;
}

.modal-footer {
    margin-top: 20px;
    max-height: 20px;
}

.linked-deal {
    width: auto;
    color: white;
    position: relative;
    border-radius: 5px;
}

.linked-deal.flex-container {
    display: flex;
    justify-content: space-between; /* This ensures the content is spaced out */
    align-items: center; /* This vertically aligns the content in the center */
    padding: 5px;
    font-weight: bold;
    font-size: 1.2em;
    overflow-y: hidden;
    padding-left: 40px;
}

.linked-deal:hover .hover-info {
    visibility: visible;
    opacity: 1;
}

.hover-info {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);  /* Semi-transparent white background */
    padding: 5px;
    color: black;
    border-radius: 5px;
    z-index: 1;  /* To ensure the hover content appears above the deal content */
}

.dealInfo.flex-item {
    flex-grow: 1;
    text-align: right; /* This ensures the text is right-aligned */
    margin-right: 40px;
}

.deal-info {
    justify-content: right;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-footer-textarea {
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    resize: vertical;
}

.scroll-btns {
    color: white;
    background-color: black;
    font-size: 20px;
    padding: 5px;
    border-radius: 3px;
}

.asset-settings.hidden {
    display: none;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    gap: 5px; /* space between icon and text */
}

/* Hover effect for all buttons */
.modal-button:hover {
    transform: scale(1.05);
}

/* Image Settings button */
.modal-button.image-settings {
    background-color: #007BFF; /* Blue color */
    color: white;
}

.modal-button.image-settings:hover {
    background-color: #0056b3; /* Darker blue */
}

/* Add Category button */
.modal-button.add-category {
    background-color: #28a745; /* Green color */
    color: white;
}

.modal-button.add-category:hover {
    background-color: #1e7e34; /* Darker green */
}

/* Save button */
.modal-button.save {
    background-color: #ffc107; /* Yellow color */
    color: black;
}

.modal-button.save:hover {
    background-color: #d39e00; /* Darker yellow */
}

/* Close button */
.modal-button.close {
    background-color: #dc3545; /* Red color */
    color: white;
}

.modal-button.close:hover {
    background-color: #c82333; /* Darker red */
}

/* Icon styles for buttons */
.modal-button i.fa {
    font-size: 16px; /* Adjust icon size */
}

.modal-footer-buttons {
    display: flex;
    justify-content: space-between; /* This will ensure maximum space between the two buttons */
    align-items: center;
    gap: 10px; /* If you want a gap between the buttons */
}

.sheet-name-input {
    padding: 7px;
    border-radius: 5px;
}

.hidden {
    display: none;
  }



  .image-upload-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 20px;
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
  }

  .image-upload-section-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .bold {
    font-weight: bold;
  }

  .deals-counter {
    color: white;
    font-weight: bold;
  }

  .sync-message {
    color: red;
    font-size: 20px;
    margin-left: 10px;
    animation: fade-out 10s forwards;
}

.dealsheet-saving-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.dealsheet-saving-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    animation: dealsheet-spin 1s linear infinite;
}

.dealsheet-saving-text {
    color: white;
    margin-top: 10px;
    font-size: 18px;
}

@keyframes dealsheet-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}


  @media screen and (max-width: 1085px) {
    .image-upload-section-container {
        flex-direction: column;
        overflow-y: auto;
      }

      .image-upload-section {
        margin: 5px;
        padding: 5px;
    }
  }

  @media screen and (max-width: 800px) {
    .sheet-modal-content {
        width: 100vw;
        max-width: 100vw;
        max-height: 100vh;
    }

    .image-upload-section-container {
        flex-direction: column;
        overflow-y: auto;
      }

    .image-upload-section {
        margin: 5px;
        padding: 5px;
    }
    .modal-button {
        font-size: 12px;
    }

    .category-header {
        display: block;
    }

    .deals-counter {
        white-space: nowrap;
    }

    .linked-deal.flex-container {
        display: block;
    }

    .hover-info {
        white-space: nowrap;
        background-color: rgba(255, 255, 255, 1);
    }

    .categories-scrollable {
        padding: 0;
    }

    .dealInfo.flex-item {
        text-align: left;
    }

  }