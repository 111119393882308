.wl-cont {
    position: relative;
    text-align: center;
  }
  
  .form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure the form is above the image */
  }
  
  .wl-cont img {
    width: 100%;
    height: auto;
  }