.store-selection-page {
    text-align: center;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px; /* Padding at the top */
    padding-bottom: 60px; /* Padding at the bottom */
}

.store-selection-page2 {
    text-align: center;
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG3.png');
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px; /* Padding at the top */
    padding-bottom: 60px; /* Padding at the bottom */
}

.select-title {
    margin-bottom: 40px; /* Margin for the title */
    font-size: 36px;
    color: #fff;
}

.select-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: 100%;
    width: 80%;
    max-height: 70vh;
    margin: auto; /* Center the container within the parent */
    box-sizing: border-box;
}

.select-store-button {
    margin: 20px 0; /* Margin around buttons */
    padding: 20px 40px;
    cursor: pointer;
    font-weight: bold;
    min-width: 200px;
    font-size: 20px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.select-store-button:hover, .select-store-button:focus {
    background-color: #0056b3;
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(255,165,0,0.7);
}

.tv-wrapper {
    background-color: rgba(0,0,0,0.7);
    border-radius: 20px;
    padding: 10px;
}

.device-name-input {
    padding: 10px;
    margin: 10px;
    background-color: black;
    color: white;
}

.vertical-button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons horizontally */
    gap: 20px; /* Space between buttons */
}