.stores-page {
    background-image: url('https://d27oebqltxtb0s.cloudfront.net/DashboardBG4.png');
    background-size: 100vw;
    background-position: center top -60vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 10px;
}

.center-logo {
    width: 100px;  /* Adjust width as necessary */
    height: auto;
}

.stores-list-container {
    padding: 5px;
    background-color: #68f60c;
    overflow-y: hidden; /* to ensure no double scrollbars appear */
    border-radius: 10px;
}

.stores-list {
    max-height: 80vh;  /* limits to its parent's height */
    overflow-y: auto;  /* scroll when content overflows */
    padding: 10px;
    background-color: black;
    border-radius: 10px;
}

.store-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    border-radius: 10px;
}

.store-logo {
    width: auto;  /* Adjust width as necessary */
    height: 50px;
    margin-right: 10px;
}

.store-name {
    flex-grow: 1;
    text-align: center;
    font-size: 24px;
    color: white;
}

.btn-edit, .btn-delete {
    margin-left: 5px;
    border-radius: 5px;
}

.btn-back, .btn-add {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 150px;
    background-color: #68f60c; /* green color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    border-radius: 5px; /* Add rounded corners */
    white-space: nowrap;
}

.btn-back:hover, .btn-add:hover, .btn-Export:hover {
    background-color: #45a049; /* a shade darker on hover */
}

.btn-edit {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 80px;
    background-color: #7061AC; /* green color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    white-space: nowrap;
}

.btn-Export {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 110px;
    background-color: #0056b8; /* green color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    border-radius: 5px;
}

.btn-edit:hover {
    background-color: #45a049; /* a shade darker on hover */
}

.btn-duplicate, .btn-Export {
    white-space: nowrap;
}

.btn-delete {
    padding: 10px 20px;
    border: none;
    margin: 0 2px;
    width: 30vw;
    max-width: 50px;
    background-color: #D15091; /* red color */
    color: black;
    cursor: pointer;
    transition: background-color 0.3s; /* smooth hover effect for buttons */
    font-size: 16px;
    white-space: nowrap;
}

.btn-duplicate {
    margin: 0 2px;
    padding: 10px 20px;
    border: none;
    background-color: #19be8f;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    border-radius: 5px;
}

.btn-delete:hover {
    background-color: #45a049; /* a shade darker on hover */
}

/* Media query for small screens */
@media screen and (max-width: 1000px) {
    .dash-logo {
        display: none;
    }
    .stores-list {
        max-height: 70vh;  /* limits to its parent's height */
        overflow-y: auto;  /* scroll when content overflows */
        padding: 10px;
        background-color: black;
    }
}


@media screen and (max-width: 800px) {
    .btn-back, .btn-add, .btn-edit, .btn-delete, .btn-play, .btn-Export, .image-settings, .add-category, .btn-duplicate {
        font-size: 10px !important;
    }

    .store-name {
        font-size: 14px;
    }

    .store-logo {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    
}